<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row mt-3 p-3 align-items-center">
                <div class="col-md-6 text-md-left text-center">
                  <h2 class="page-title mb-md-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i></a
                    >{{ id ? $t("label.Edit") : $t("label.Add") }} {{ $t("label.Product") }}
                  </h2>
                </div>
                <div class="col-md-6 text-right d-md-flex justify-content-end mb-3"></div>
              </div>
              <div class="card card-body form-style content-card">
                <div class="row justify-content-center">
                  <div class="col-lg-12 col-md-12 text-center">
                    <label for="">{{ $t("label.Product image") }}</label>
                    <div class="custom-select-img">
                      <div class="image-container mb-2 mx-auto col-lg-12 mb-3">
                        <div class="row">
                          <div
                            class="mb-3 col-md-4"
                            v-for="(image, index) in ImageList"
                            :key="index"
                          >
                            <div class="text-center">
                              <img
                                class="mx-2"
                                style="
                                  border: 1px solid rgb(204, 204, 204);
                                  width: 220px !important;
                                  height: 150px !important;
                                "
                                :src="
                                  image.image
                                    ? image.image
                                    : '/static/img/upload-placeholder.jpg'
                                "
                                ref="'image'"
                                id="img_hoder_1"
                              />
                              <i
                                sty
                                class="bi bi-x-circle-fill close"
                                v-on:click="deleteImage(image, index)"
                              ></i>
                              <br />
                            </div>
                          </div>
                        </div>

                        <input
                          type="file"
                          class="btn upload-img"
                          id="AddPhoto"
                          accept="logo/*"
                          multiple
                          @change="onChange($event)"
                          hidden="true"
                        />
                        <div
                          style="cursor: pointer"
                          class="mb-3 justify-content-center"
                          v-if="ImageList.length <= 0"
                          v-on:click="openImages"
                        >
                          <div class="image-upload-wrap">
                            <div class="drag-text text-center">
                              <img
                                id="preview"
                                :src="'/images/Imageplace-holder.svg'"
                                width="40"
                              />
                              <h3 class="heading font-20">{{ $t("label.Upload a image") }}</h3>
                              <p class="font-12 text-secondary mb-1" for="pickFile">
                                PNG, GIF, JPEG. {{ $t("label.(Max 10 mb)") }}
                              </p>
                              <p class="font-12 text-secondary mb-0">{{ $t("label.(Optional)") }}</p>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="ImageList.length > 0"
                          class="rounded mx-2"
                          style="margin: auto; width: 100%; padding: 10px"
                        >
                          <button
                            class="btn"
                            style="background-color: #17a2b8"
                            @click="openImages()"
                          >
                            {{ $t("label.Add More") }} <i class="bi bi-plus-circle-fill"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="">{{ $t("label.Product Name") }}</label>
                      <span class="text-danger">*</span>
                      <Field
                        type="text"
                        id="fname"
                        name="fname"
                        v-model="detail.title"
                        class="form-control filled"
                        rules="required:name"
                        :validateOnInput="true"
                      />
                    </div>
                    <ErrorMessage name="fname" class="validation-msg" />
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="text-secondary-2 font-14 mb-0">{{ $t("label.Description") }}</label>
                      <span class="text-danger">*</span>
                      <textarea
                        id="description"
                        name="description"
                        class="form-control filled"
                        style="height: 70px"
                        v-model="detail.description"
                        cols="30"
                        rows="4"
                        rules="required:description"
                        :validateOnInput="true"
                      ></textarea>
                    </div>
                    <ErrorMessage name="description" class="validation-msg" />
                  </div>
                  <div class="col-6">
                    <label>{{ $t("label.Category") }}</label>
                    <span class="text-danger">*</span>

                    <!-- <div class="selectParent mb-md-0 mr-md-3"> -->
                    <Field
                      as="select"
                      class="selectParent mb-md-0 mr-md-3"
                      v-model="detail.category_id"
                      name="category"
                      rules="required:category,true"
                      v-on:change="getSubCategory"
                    >
                      <option value="">{{ $t("label.Select") }}</option>
                      <option
                        v-for="(data, index) in categoryList"
                        :key="index"
                        :value="data.id"
                      >
                        {{ data.title }}
                      </option>
                    </Field>
                    <!-- </div> -->
                    <ErrorMessage name="category" class="validation-msg mt-2" />
                  </div>
                  <div class="col-6">
                    <label>{{ $t("label.Sub-Category") }}</label>
                    <span class="text-danger">*</span>
                    <Field
                      as="select"
                      class="selectParent mb-md-0 mb-4 mr-md-3"
                      v-model="detail.sub_category_id"
                      name="subcategory"
                    >
                      <option value="">{{ $t("label.Select") }}</option>
                      <option
                        v-for="(data, index) in subCategoryList"
                        :key="index"
                        :value="data.id"
                      >
                        {{ data.title }}
                      </option>
                    </Field>
                    <ErrorMessage name="subcategory" class="validation-msg mt-2" />
                  </div>
                  <div class="col-12 my-4">
                    <div
                      class="custom-control custom-checkbox mr-md-4 mb-md-0 mb-2 d-inline"
                      data-toggle="collapse"
                      data-target="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <input
                        type="checkbox"
                        id="details"
                        name="details"
                        class="custom-control-input"
                        v-model="checkedTrue"
                      />
                      <label class="custom-control-label font-medium" for="details"
                        >{{ $t("label.Details") }}</label
                      >
                    </div>
                    <br />
                    <hr />
                    <div class="row collapse" id="collapseExample">
                      <div
                        class="col-md-4"
                        v-for="(data, index) in attributeList"
                        :key="index"
                      >
                        <div class="form-group">
                          <label class=""> {{ data.title }}</label>
                          <input
                            type="text"
                            class="form-control"
                            id="values"
                            name="values"
                            v-model="data.values"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <hr />
                  </div>

                  <!-- <div class="form-group">
                    <label class="text-secondary-2 font-14 d-block">Out of stock</label>
                    <div class="custom-control custom-switch d-inline mr-3">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="shwitch_1"
                        true-value="Y"
                        false-value="N"
                        v-model="detail.is_out_of_stock"
                        v-on:change="
                    setAvailability( detail.is_out_of_stock)
                  "
                      />
                      <label class="custom-control-label" for="shwitch_1"></label>
                    </div>
                  </div> -->
                  <div class="text-center">
                    <button
                      type="button"
                      id="save-button"
                      class="btn btn-brand-1 mr-4"
                      @click="save()"
                    >
                      {{ $t("label.Save") }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-brand-1 cancel"
                      v-on:click="$router.go(-1)"
                    >
                      {{ $t("label.Cancel") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { Field, ErrorMessage } from "vee-validate";
export default {
  name: "AddProduct",
  components: {
    Field,
    ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {
        title: "",
        attributes: [],
        status: "APPROVED",
      },
      file: "",
      imageObj: {},
      categoryList: [],
      subCategoryList: [],
      attributeList: [],
      attributeListData: [],
      ImageList: [],
      product_id: "",
      image_index: "",
    };
  },
  mounted() {
    this.getCategory();
    this.getAttributes();
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "product/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;

          this.ImageList = res.info.images;

          // this.attributeList = res.info.attributes[0];
          console.log("coming data from edit controller", res);
          // this.attributeList.push(res.info.attributes);
          console.log("att", res.info.attributes);
          // for (var i in res.info.attributes) {
          //   // console.log("here");
          //   for (var j in this.attributeList) {
          //     if (res.info.attributes[i].title == this.attributeList[j].title) {
          //       this.attributeList[j].values = res.info.attributes[i].values;
          //     }
          //   }
          // }

          res.info.attributes.forEach((obj) => {
            this.attributeListData.push({
              code: obj.title,
              value: obj.values,
              title: obj.title,
            });
          });

          this.attributeList = this.attributeListData;
          if (this.attributeList.length <= 0) {
            this.getAttributes;
          }
          console.log("console value is this>>>>>>>>>>>", this.attributeList);
          // this.attributeList = res.attributes
          this.getSubCategory();
        })
        .catch(() => {});
    },
    getCategory() {
      this.$api
        .getAPI({
          _action: "product/categories",
        })
        .then((res) => {
          this.categoryList = res.list;
          console.log("this is category list", this.categoryList);
        })
        .catch(() => {});
    },
    getSubCategory() {
      var id = this.detail.category_id;
      console.log("calling Subcategory Api " + id);
      var obj = {};
      obj.parent_category_id = id;
      this.$api
        .getAPI({
          _action: "product/categories",
          _body: obj,
        })
        .then((res) => {
          this.subCategoryList = res.list;
          console.log("this is subcategory list", this.subCategoryList);
        })
        .catch(() => {});
    },
    save() {
      this.detail.attributes = [];
      for (var i in this.attributeList) {
        if (this.attributeList[i].values) {
          var obj = {
            code: this.attributeList[i].code,
            values: this.attributeList[i].values,
          };

          this.detail.attributes.push(obj);
        }
      }
      if (this.id) {
        this.$api
          .putAPI({
            _action: "product/" + this.id,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.product_id = res.product_id;
            console.log("this is product id ", this.product_id);
            this.uploadFileStepByStep();
            this.$router.push("/manage-product-service");
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "product",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.product_id = res.product_id;
            console.log("this is product id ", this.product_id);
            if (this.ImageList.length > 0) {
              this.uploadFileStepByStep();
            }
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.push("/manage-product-service");
          })
          .catch(() => {});
      }
    },
    getAttributes() {
      this.$api
        .getAPI({
          _action: "product/attributes",
        })
        .then((res) => {
          res.list.forEach((obj) => {
            this.attributeList.push({
              code: obj.code,
              value: obj.value,
              title: obj.title,
            });
          });
          if (!this.id) {
            this.attributeList;
          } else {
            this.getDetail();
          }
        })
        .catch(() => {});
    },
    // selectedFile(e) {
    //   let files = e.target.files;
    //   if (
    //     files[0].type.toLowerCase() != "image/jpeg" &&
    //     files[0].type.toLowerCase() != "image/png"
    //   ) {
    //     alert(this.$t("label.Invalid file formate, please use jpeg or png file."));
    //     return;
    //   }
    //   const size = (files[0].size / 1024 / 1024).toFixed(2);
    //   if (size > 10) {
    //     alert(this.$t("label.File must be less then 10 MB"));
    //     return;
    //   }
    //   var tmppath = URL.createObjectURL(files[0]);
    //   this.file = files[0];
    //   document.getElementById("preview").src = tmppath;
    // },
    // uploadImage(id) {
    //   this.$api
    //     .uploadImageAPI({
    //       _action: "product/" + id + "/image",
    //       _key: "image",
    //       _file: this.file,
    //     })
    //     .then((res) => {
    //       this.$notify({
    //         type: "success",
    //         text: res.message,
    //       });
    //     });
    // },
    removeImage(index) {
      this.image_index = index;

      console.log("console log for remove image from the seleted images", index);
      this.ImageList.splice(index, 1);
    },
    deleteImage(data, index) {
      if (!data.id) {
        this.image_index = index;

        console.log("console log for remove image from the seleted images", index);
        this.ImageList.splice(index, 1);
        return;
      }
      this.$api
        .deleteAPI({
          _action: "product/" + data.id + "/image",
        })
        .then((res) => {
          this.$router.go(0);
          this.$noty.success("" + res.message, {
            timeout: 2000,
            layout: "topCenter",
            theme: "semanticui",
          });
        })
        .catch((e) => {
          this.$noty.error("" + e.message, {
            timeout: 2000,
            layout: "topCenter",
            theme: "semanticui",
          });
        });
    },
    openImages() {
      document.getElementById("AddPhoto").click();
    },
    onChange(e) {
      var selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        let img = {
          image: URL.createObjectURL(selectedFiles[i]),
          file: selectedFiles[i],
        };
        this.ImageList.push(img);
      }
    },
    async uploadFileStepByStep() {
      for (let i = 0; i < this.ImageList.length; i++) {
        console.log("console log for images list", this.ImageList[i].file);
        if (this.ImageList[i].file) {
          await this.uploadImage(this.ImageList[i].file);
        }
      }
      this.$router.go(-1);
    },
    async uploadImage(file) {
      this.$api
        .uploadImageAPI({
          _action: "product/" + this.product_id + "/image",
          _key: "image",
          _file: file,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
        });
    },
  },
};
</script>

<style>
.img_wrp {
  display: inline-block;
  position: relative;
}
.close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 42px !important;
}
</style>