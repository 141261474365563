<template>
  <!-- <div class="scroll-sidebar mb-3">
    <nav class="sidebar-nav">
      <ul class="nav-bar pl-0" id="leftNav">
        <li class="nav-item mt-3">
          <a class="nav-link menu_no_1" href="home.php"
            ><i class="bx bxs-dashboard side-nav-icon"></i
            ><span class="hide-menu">Dashboard</span></a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link menu_no_2" href="manage-products.php"
            ><i class="bx bx-package side-nav-icon"></i
            ><span class="hide-menu">Manage Products</span></a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link menu_no_3" href="manage-services.php"
            ><i class="bx bx-briefcase-alt-2 side-nav-icon"></i
            ><span class="hide-menu">Manage Services</span></a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link menu_no_4" href="manage-products-orders.php#1"
            ><i class="bx bx-shopping-bag side-nav-icon"></i
            ><span class="hide-menu">Manage Product Orders</span></a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link menu_no_5" href="manage-services-orders.php"
            ><i class="bx bx-briefcase-alt side-nav-icon"></i
            ><span class="hide-menu">Manage Service Orders </span></a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link menu_no_6" href="earnings.php">
            <i class="bx bx-wallet-alt side-nav-icon"></i
            ><span class="hide-menu">Earnings</span></a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link menu_no_7" href="dilivery-charges.php"
            ><i class="bx bx-purchase-tag side-nav-icon"></i
            ><span class="hide-menu">Product Dilivery Charges</span></a
          >
        </li>
      </ul>
    </nav>
  </div> -->
  <aside class="left-sidebar" id="mob_sidebar">
    <div class="scroll-sidebar no-right-sidebar">
      <nav class="sidebar-nav">
        <ul class="nav-bar pl-0" id="leftNav">
          <li
            v-on:click="setTabValue()"
            class="nav-item cursor-pointer"
            v-for="item in menus"
            :key="item.id"
            :id="item.code"
          >
            <router-link :to="item.url">
              <a :class="'nav-link menu_no_1'"
                ><i :class="item.icon_class + ' icon'"></i> {{ item.name }}</a
              >
            </router-link>
            <ul class="nav-bar pl-0" id="leftNav" v-if="menus.sub_menus">
              <li
                class="nav-item cursor-pointer"
                v-for="sub_menu in list.sub_menus"
                :key="sub_menu.id"
                :id="sub_menu.code"
              >
                <router-link :to="sub_menu.url">
                  <a :class="'nav-link menu_no_1'"
                    ><i :class="sub_menu.icon_class + ' icon'"></i> {{ sub_menu.name }}</a
                  >
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      pageName: "",
      mydetail: {},
      currentUrlPath: "",
      menus: [],
      token: localStorage.getItem("access_token"),
      currentTab: location.pathname.replace("/", ""),
    };
  },
  watch: {
    "$route.fullPath"(newValue) {
      this.pageName = newValue.replace("/", "");
    },
  },
  computed: {
    detail() {
      return this.$store.getters.getUser ? this.$store.getters.getUser : {};
    },
  },
  mounted() {
    this.pageName = this.$route.fullPath.replace("/", "");
    console.log("this is page url name", this.pageName);
    this.meCall();
    this.getMenuList();
  },

  methods: {
    getCurrentUrl() {
      var currentUrl = window.location.pathname;
      this.currentUrlPath = currentUrl;
    },
    getMenuList() {
      this.$api
        .getAPI({
          _action: "/left-menus",
        })
        .then((res) => {
          this.menus = res.list;

          // if (res && res.list) {
          //   for (var i = 0; i < this.menus.length; i++) {
          //     if (this.menus[i].access) {
          //       this.menuList.push(this.getMenuDetail(this.menus[i]));
          //     }

          //     if (this.menus[i].sub_menus) {
          //       for (var j = 0; j < this.menus[i].sub_menus.length; j++) {
          //         if (this.menus[i].sub_menus[j].access) {
          //           this.menuList.push(this.getMenuDetail(this.menus[i].sub_menus[j]));
          //         }

          //         if (this.menus[i].sub_menus[j].sub_menus) {
          //           for (
          //             var k = 0;
          //             k < this.menus[i].sub_menus[j].sub_menus.length;
          //             k++
          //           ) {
          //             if (this.menus[i].sub_menus[j].sub_menus[k].access) {
          //               this.menuList.push(
          //                 this.getMenuDetail(this.menus[i].sub_menus[j].sub_menus[k])
          //               );
          //             }
          //           }
          //         }
          //       }
          //     }
          //   }
          //   if (window.location.pathname == "/access-denied") {
          //     this.$router.go(-1);
          //   }
          //   if (
          //     localStorage.getItem("active_menu_id") != "" ||
          //     localStorage.getItem("active_menu_id") != undefined ||
          //     localStorage.getItem("active_menu_id") != null
          //   ) {
          //     var menu_element = document.getElementById(
          //       localStorage.getItem("active_menu_id")
          //     );
          //     menu_element.classList.add("mm-active");
          //   }
          // }
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            text: e.message,
          });
        });
    },
    meCall() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.mydetail = res.info;
        })
        .catch(() => {});
    },
    setTabValue() {
      localStorage.setItem("tab_name", "NEW_VISIT");
    },
    // getMenuDetail(menu) {
    //   return {
    //     title: menu.name,
    //     url: menu.url,
    //     can_read: menu.access.can_read,
    //     can_delete: menu.access.can_delete,
    //     can_create: menu.access.can_create,
    //     can_update: menu.access.can_update,
    //     can_print: menu.access.can_print,
    //   };
    // },
    // getMenuAccess(url) {
    //   if (this.menuList.length > 0) {
    //     if (!url) {
    //       url = window.location.pathname;
    //     }
    //     for (var i = 0; i < this.menuList.length; i++) {
    //       if (this.menuList[i].url == url) {
    //         return this.menuList[i];
    //       }
    //     }
    //   } else {
    //     this.$router.push("/access-denied");
    //   }
    // },
  },
};
</script>
