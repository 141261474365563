<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mt-3 p-3 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">{{ $t("label.Dashboard") }}</h2>
            </div>
          </div>
          <ul
            class="nav nav-pills mb-3 tab-style-1 bg-light justify-content-center"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <a
                v-bind:class="current_active_tab == 'PRODUCT' ? 'active  px-4' : ''"
                class="nav-link"
                id="product-tab"
                data-toggle="pill"
                href="#product"
                v-on:click="setTabDetail('PRODUCT')"
                role="tab"
                aria-controls="product"
                aria-selected="true"
                >{{ $t("label.Products") }}</a
              >
            </li>
            <li class="nav-item px-4" role="presentation">
              <a
                v-bind:class="current_active_tab == 'SERVICE' ? 'active  px-4' : ''"
                class="nav-link"
                id="services-tab"
                v-on:click="setTabDetail('SERVICE')"
                data-toggle="pill"
                href="#services"
                role="tab"
                aria-controls="services"
                aria-selected="false"
                >{{ $t("label.Services") }}</a
              >
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              v-bind:class="current_active_tab == 'PRODUCT' ? 'active show' : ''"
              class="tab-pane fade"
              id="product"
              role="tabpanel"
              aria-labelledby="product-tab"
            >
              <div class="row justify-content-center">
                <div class="col-lg-3 col-md-4 col-6 mb-3">
                  <div
                    class="card p-4 shadow-sm text-center border-0 card-hover"
                    v-on:click="$router.push('/manage-product-service')"
                  >
                    <img
                      src="images/all-products.svg"
                      width="92"
                      alt=""
                      class="mx-auto mb-3"
                    />
                    <p class="font-18 mb-2">{{ $t("label.All Products") }}</p>
                    <h2 class="text-brand mb-0">
                      {{ detail.product_count ? detail.product_count : "00" }}
                    </h2>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6">
                  <div
                    class="card p-4 shadow-sm text-center border-0 card-hover"
                    v-on:click="$router.push('/manage-product-order')"
                  >
                    <img src="images/orders.svg" width="92" alt="" class="mx-auto mb-3" />
                    <p class="font-18 mb-2">{{ $t("label.Product Orders") }}</p>
                    <h2 class="text-brand mb-0">
                      {{ detail.total_product_order ? detail.total_product_order : "00" }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-bind:class="current_active_tab == 'SERVICE' ? 'active show' : ''"
              class="tab-pane fade"
              id="services"
              role="tabpanel"
              aria-labelledby="services-tab"
            >
              <div class="row justify-content-center">
                <div class="col-lg-3 col-md-4 col-6">
                  <div
                    class="card p-4 shadow-sm text-center border-0 card-hover"
                    v-on:click="$router.push('/manage-product-service')"
                  >
                    <img
                      src="images/all-services.svg"
                      width="92"
                      alt=""
                      class="mx-auto mb-3"
                    />
                    <p class="font-18 mb-2">{{ $t("label.All Services") }}</p>
                    <h2 class="text-brand mb-0">
                      {{ detail.service_count ? detail.service_count : "00" }}
                    </h2>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6">
                  <div
                    class="card p-4 shadow-sm text-center border-0 card-hover"
                    v-on:click="$router.push('/manage-service-order')"
                  >
                    <img
                      src="images/job-services.svg"
                      width="92"
                      alt=""
                      class="mx-auto mb-3"
                    />
                    <p class="font-18 mb-2">{{ $t("label.Services Orders") }}</p>
                    <h2 class="text-brand mb-0">
                      {{ detail.total_Service_order ? detail.total_Service_order : "00" }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "DashBoard",
  components: {},
  data() {
    return {
      detail: {},
      current_active_tab: "PRODUCT",
      token: localStorage.getItem("access_token"),
    };
  },
  mounted() {
    this.current_active_tab = localStorage.getItem("tab");
    if (!this.current_active_tab) {
      this.setTabDetail("PRODUCT");
    }
    this.getDashBoard();
  },
  methods: {
    setTabDetail(type) {
      this.current_active_tab = type;
      localStorage.setItem("tab", type);
    },
    getDashBoard() {
      this.$api
        .getAPI({
          _action: "get-counts",
        })
        .then((res) => {
          console.log("this is counts of dashboard", res.info);
          this.detail = res.info;
        })
        .catch(() => {});
    },
  },
};
</script>

