<template>
  <div class="d-lg-flex no-right-sidebar" id="main-wraper">
    <div class="page-wrapper no-right-sidebar">
      <div class="page-content">
        <div class="row mb-4 justify-content-center">
          <div class="col-lg-12">
            <div class="row p-3 align-items-center text-dark">
              <div class="col-md-6 col-5">
                <h2 class="page-title mb-md-0">
                  <a
                    href="javascript:void(0)"
                    v-on:click="$router.go(-1)"
                    class="back-arrow"
                  >
                    <i class="bx bx-arrow-back"></i
                  ></a>
                  {{ detail.title }}
                </h2>
              </div>
            </div>
            <div
              class="card card-body content-card mb-4 rounded-l border-0 shadow-sm"
              style="width: 120%"
            >
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <form class="form-style">
                    <Form class="form-style floating-form" @submit="update()">
                      <div class="row justify-content-center mb-3">
                        <div class="col-md-10">
                          <div class="row">
                            <div class="col-md-12 mt-3">
                              <label>{{ $t("label.Language") }}</label>
                              <span class="text-danger">*</span>
                              <Field
                                as="select"
                                class="selectParent mb-md-0 mr-md-3"
                                v-model="detail.lang_code"
                                name="category"
                                rules="required:language,true"
                              >
                                <option value="">{{ $t("label.Select") }}</option>
                                <option value="en"> {{ $t("label.English") }}</option>
                                <option value="ar"> {{ $t("label.Arabic") }}</option>
                              </Field>
                              <ErrorMessage name="category" class="validation-msg mt-2" />
                            </div>
                            <div class="col-md-12 mt-3">
                              <editor
                                api-key="no-api-key"
                                placeholder="description"
                                v-model="detail.description"
                              ></editor>
                            </div>
                            <ErrorMessage name="description" class="validation-msg" />
                          </div>
                        </div>
                      </div>
                      <div class="text-center mt-3">
                        <button
                          type="button"
                          class="btn btn-brand-1 mb-3 mr-2 px-5"
                          v-on:click="$router.go(-1)"
                        >
                          {{ $t("label.Cancel") }}
                        </button>
                        <button class="btn btn-brand-1 mb-3 px-5" id="save-button">
                          {{ $t("label.Save") }}
                        </button>
                      </div>
                    </Form>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "EditContent",

  components: {
    Form,
    ErrorMessage,Field,
    Editor,
  },

  data() {
    return {
      id: this.$route.params.id,
      detail: {lang_code:""},
    };
  },

  mounted() {
    if (this.id) {
      this.getDetail();
    }
  },

  methods: {
    update() {
      this.$api
        .putAPI({
          _action: "content/" + this.id,
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$router.go(-1);
        })
        .catch(() => {});
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "content/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },
  },
};
</script>

