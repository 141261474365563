<template>
  <div class="d-lg-flex no-right-sidebar" id="main-wraper">
    <div class="page-wrapper no-right-sidebar">
      <div class="page-content">
        <div class="row mb-4 justify-content-center">
          <div class="col-lg-12">
            <div class="row p-3 align-items-center text-dark">
              <div class="col-md-6 col-5">
                <h2 class="page-title mb-md-0">
                  <a
                    href="javascript:void(0)"
                    v-on:click="$router.go(-1)"
                    class="back-arrow"
                  >
                    <i class="bx bx-arrow-back"></i
                  ></a>
                  {{ $t("label.Add Password") }}
                </h2>
              </div>
            </div>
            <div
              class="card card-body content-card mb-4 rounded-l border-0 shadow-sm"
              style="width: 120%"
            >
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                ></div>

                <Form class="form-style floating-form" @submit="reset()">
                  <div class="row justify-content-center mb-3">
                    <div class="col-md-10">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="floating-form-group">
                            <Field
                              :type="passwordFieldType"
                              id="password"
                              name="password"
                              v-model="detail.password"
                              class="form-control filled"
                              rules="required:password"
                              :validateOnInput="true"
                            />
                            <label for="password">{{ $t("label.Password") }}</label>
                            <i class="bx bx-lock icon"></i>
                            <span
                              class="input-group-text bg-white"
                              id="basic-addon2"
                              style="border-radius: 0px 8px 8px 0px"
                            >
                              <a class="psw-icon" v-on:click="switchVisibility"
                                ><img
                                  :src="image"
                                  width="20"
                                  style="margin-bottom: 10px" /></a
                            ></span>
                          </div>
                          <ErrorMessage name="password" class="validation-msg" />
                        </div>
                        <div class="col-md-12">
                          <div class="floating-form-group">
                            <Field
                              :type="newPasswordFieldType"
                              id="confirm_password"
                              name="confirm_password"
                              v-model="detail.confirm_password"
                              class="form-control filled"
                              rules="required:confirm password|confirmed:@password"
                              :validateOnInput="true"
                            />
                            <label for="password">{{ $t("label.Confirm Password") }}</label>
                            <i class="bx bx-lock icon"></i>
                            <span
                              class="input-group-text bg-white"
                              id="basic-addon2"
                              style="border-radius: 0px 8px 8px 0px"
                            >
                              <a class="psw-icon" v-on:click="switchVisibilityNew"
                                ><img
                                  :src="imageNew"
                                  width="20"
                                  style="margin-bottom: 10px" /></a
                            ></span>
                          </div>
                          <ErrorMessage name="confirm_password" class="validation-msg" />
                        </div>
                      </div>

                      <div class="text-center mt-3">
                        <button
                          id="cancel-button"
                          class="btn btn-brand-1 mb-3 mr-2 px-5"
                          v-on:click="$router.go(-1)"
                        >
                          {{ $t("label.Cancel") }}
                        </button>
                        <button id="submit-button" class="btn btn-brand-1 mb-3 px-5">
                          {{ $t("label.Save") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "AdminPassword",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
      newPasswordFieldType: "password",
      passwordFieldType: "password",
      image: "/images/show.png",
      imageNew: "/images/show.png",
    };
  },

  mounted() {},
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },

    reset() {
      console.log("first");
      {
        this.$api
          .putAPI({
            _action: "user/" + this.id + "/change-password",
            _body: this.detail,
            _buttonId: "submit-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {});
      }
    },
  },
};
</script>

