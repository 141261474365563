<template>
  <div class="d-lg-flex no-right-sidebar" id="main-wraper">
    <div class="page-wrapper no-right-sidebar">
      <div class="page-content">
        <div class="row mb-4 justify-content-center">
          <div class="col-lg-12">
            <div class="row p-3 align-items-center">
              <div class="col-md-6 col-5">
                <h2 class="page-title mb-md-0">
                  <a
                    href="javascript:void(0)"
                    v-on:click="$router.go(-1)"
                    class="back-arrow"
                  >
                    <i class="bx bx-arrow-back"></i
                  ></a>
                  {{ $t("label.Product Details") }}
                </h2>
              </div>
            </div>
            <div
              class="card card-body content-card mb-4 rounded-l border-0 shadow-sm"
              style="width: 130%"
            >
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="card">
                        <div class="card-body">
                          <div class="col-lg-12 col-md-12 mx-auto mb-4 text-center">
                            <div class="mb-3">
                              <img
                                :src="
                                  detail?.images?.image
                                    ? detail?.images?.image
                                    : '/images/profile.png'
                                "
                                class="rounded-circle border border-light"
                                style="border-width: 3px !important"
                                alt="image thumb"
                                id="preview"
                                width="130"
                                height="130"
                              />
                            </div>
                            <h5>{{ $t("label.Product image") }}</h5>
                          </div>
                          <br /><br />
                          <h4 class="" style="font-weight: bolder !important">
                            {{ $t("label.Product Info") }}
                          </h4>
                          <hr />
                          <br />
                          <div class="row">
                            <div class="col-lg-12 col-md-12 d-flex flex-row mb-2">
                              <h6 style="font-weight: bolder !important">
                                {{ $t("label.Supplier Name") }}:
                              </h6>
                              <h6 class="mx-3">{{ detail?.supplier?.name }}</h6>
                            </div>
                            <div class="col-lg-6 col-md-6 d-flex flex-row">
                              <h6 style="font-weight: bolder !important">
                                {{ $t("label.Product Name") }}:
                              </h6>
                              <h6 class="mx-3">{{ detail.title }}</h6>
                            </div>
                            <div class="col-lg-6 col-md-6 d-flex flex-row">
                              <h6 style="font-weight: bolder !important">{{ $t("label.Category") }} :</h6>
                              <h6 class="mx-3">{{ category.title }}</h6>
                            </div>
                          </div>
                          <br />
                          <div class="row">
                            <div class="col-lg-6 col-md-6 d-flex flex-row">
                              <h6 style="font-weight: bolder !important">
                                {{ $t("label.Sub Category") }}:
                              </h6>
                              <h6 class="mx-3">{{ subCategory.title }}</h6>
                            </div>
                            <div class="col-lg-6 col-md-6 d-flex flex-row">
                              <h6 style="font-weight: bolder !important">
                                {{ $t("label.Description") }}:
                              </h6>
                              <h6 class="mx-3">{{ detail.description }}</h6>
                            </div>
                          </div>
                          <hr />
                          <br />
                          <div class="row" v-show="productAttributes.length > 0">
                            <div class="col-lg-12 col-md-12">
                              <h4 style="font-weight: bolder !important">
                                {{ $t("label.Attributes Detail") }}
                              </h4>
                            </div>
                            <br />
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <table
                                class="table table-style-1 table-responsive"
                                id="sorting_tbl"
                              >
                                <thead>
                                  <tr>
                                    <th class="sorting cursor-pointer">{{ $t("label.S_No") }}</th>
                                    <th class="sorting cursor-pointer">{{ $t("label.Title") }}</th>
                                    <th class="sorting cursor-pointer">{{ $t("label.value") }}</th>
                                    <th>{{ $t("label.icon") }}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(data, index) in productAttributes"
                                    :key="index"
                                  >
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data?.title }}</td>
                                    <td>{{ data?.values }}</td>
                                    <td>
                                      <img
                                        :src="
                                          data?.icon
                                            ? data?.icon
                                            : '/images/placeholder.png'
                                        "
                                        class="border border-light"
                                        style="border-width: 3px !important"
                                        alt="image thumb"
                                        width="50"
                                        height="50"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <br />
                          <div class="" v-if="supplierDetails != null">
                            <h4 class="" style="font-weight: bolder !important">
                              {{ $t("label.Supplier Info") }}
                            </h4>
                            <hr />
                            <br />
                            <div class="row">
                              <div class="col-lg-6 col-md-6 d-flex flex-row">
                                <h6 style="font-weight: bolder !important">
                                   {{ $t("label.Supplier Name") }}:
                                </h6>
                                <h6 class="mx-3">{{ supplierDetails.name }}</h6>
                              </div>
                              <div class="col-lg-6 col-md-6 d-flex flex-row">
                                <h6 style="font-weight: bolder !important">Email :</h6>
                                <h6 class="mx-3">{{ supplierDetails.email }}</h6>
                              </div>
                            </div>
                            <br />
                            <div class="row">
                              <div class="col-lg-6 col-md-6 d-flex flex-row">
                                <h6 style="font-weight: bolder !important">
                                   {{ $t("label.Mobile Number") }}:
                                </h6>
                                <h6 class="mx-3">{{ supplierDetails.phone_number }}</h6>
                              </div>
                              <div class="col-lg-6 col-md-6 d-flex flex-row">
                                <h6 style="font-weight: bolder !important">{{ $t("label.Address") }} :</h6>
                                <h6 class="mx-3">
                                  {{ supplierDetails.address }}
                                  <a
                                    :href="
                                      'http://maps.google.com/?q=' +
                                      supplierDetails.address
                                    "
                                    class="href"
                                    >{{ $t("label.View Location") }}</a
                                  >
                                </h6>
                              </div>
                            </div>
                          </div>
                          <br /><br />
                          <div class="row" v-if="detail.status == 'PENDING'">
                            <div class="col-lg-10 col-md-10 mx-auto">
                              <div class="text-center mt-3">
                                <button
                                  type="button"
                                  id="submit-button"
                                  class="btn btn-brand-1 mb-3 mr-2 px-5"
                                  v-on:click="UpdateStatus('APPROVED')"
                                >
                                  {{ $t("label.Accept") }}
                                </button>
                                <button
                                  class="btn btn-brand-1 mb-3 px-5"
                                  style="background-color: red;"
                                  id="submit-button"
                                  v-on:click="UpdateStatus('REJECTED')"
                                >
                                  {{ $t("label.Reject") }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "EditSupplier",
  components: {
    // Form,
    // Field,
    // ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {
        dial_code: "+91",
        country_code: "IN",
        name: "",
        email: "",
      },
      category: {},
      subCategory: {},
      supplierDetails: {},
      productAttributes: [],
    };
  },
  mounted() {
    // alert("hello");
    if (this.id) {
      this.getProductDetail();
    }
  },
  methods: {
    UpdateStatus(status) {
      var obj = {};
      obj.status = status;
      {
        this.$api
          .putAPI({
            _action: "change/status/" + this.id,
            _body: obj,
            _buttonId: "submit-button",
          })
          .then((res) => {
            this.$router.push("/manage-product-service-approval");
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {});
      }
    },
    getProductDetail() {
      this.$api
        .getAPI({
          _action: "product/" + this.id,
        })
        .then((res) => {
          if (res.info?.images.length <= 0) {
            res.info.images.push({});
          }
          this.detail = res.info;
          this.category = res.info.category;
          this.subCategory = res.info.sub_category;
          this.supplierDetails = res.info.supplier_details;
          this.productAttributes = res.info.attributes;
        })
        .catch(() => {});
    },

    status(status) {
      var obj = {};
      obj.supplier_status = status;
      this.$api
        .postAPI({
          _action: "supplier/change-status/" + this.detail.id,
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$router.push("/manage-supplier");
        })
        .catch(() => {});
    },
    // getSupplierDetail() {
    //   this.$api
    //     .getAPI({
    //       _action: "supplier/" + this.id,
    //     })
    //     .then((res) => {
    //       this.detail = res.info;
    //       this.operating_area = res.info.operating_areas;
    //     })
    //     .catch(() => {});
    // },
  },
};
</script>
