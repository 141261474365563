<template>
  <div class="d-lg-flex no-right-sidebar" id="main-wraper">
    <div class="page-wrapper no-right-sidebar">
      <div class="page-content">
        <div class="row mb-4 justify-content-center">
          <div class="col-lg-12">
            <div class="row p-3 align-items-center">
              <div class="col-md-6 col-5">
                <h2 class="page-title mb-md-0">
                  <a
                    href="javascript:void(0)"
                    v-on:click="$router.go(-1)"
                    class="back-arrow"
                  >
                    <i class="bx bx-arrow-back"></i
                  ></a>
                  {{ $t("label.My Profile") }}
                </h2>
              </div>
            </div>
            <div
              class="card card-body content-card mb-4 rounded-l border-0 shadow-sm"
              style="width: 120%"
            >
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <form class="form-style">
                    <Form class="form-style floating-form" @submit="update()">
                      <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-5 mb-4 text-center">
                          <div class="mb-3">
                            <img
                              :src="
                                detail.avatar && detail.avatar != null
                                  ? detail.avatar
                                  : '/images/profile.png'
                              "
                              class="rounded-circle border border-light"
                              style="border-width: 3px !important"
                              alt="image thumb"
                              id="preview"
                              width="130"
                              height="130"
                            />
                          </div>
                          <button
                            type="button"
                            class="btn px-3 upload-img-merge-btn btn-secondary w-75 text-capitalize"
                            for="pickFile"
                          >
                            {{ $t("label.Change photo") }}
                            <input
                              type="file"
                              class="btn upload-img"
                              id="pickFile"
                              @change="selectedFile($event)"
                            />
                          </button>
                        </div>
                      </div>
                      <div class="row justify-content-center">
                        <div class="col-md-10">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="fname">{{ $t("label.Name") }}</label>
                                <span class="text-danger">*</span>
                                <Field
                                  type="text"
                                  id="fname"
                                  name="fname"
                                  v-model="detail.name"
                                  class="form-control filled"
                                  rules="required:name"
                                  :validateOnInput="true"
                                />
                              </div>
                              <ErrorMessage name="fname" class="validation-msg" />
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="email">{{ $t("label.Email") }}</label>
                                <span class="text-danger">*</span>
                                <Field
                                  type="email"
                                  id="email"
                                  name="email"
                                  v-model="detail.email"
                                  class="form-control filled"
                                  rules="required:email"
                                  :validateOnInput="true"
                                />
                              </div>
                              <ErrorMessage name="email" class="validation-msg" />
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="phone">{{ $t("label.Phone") }}</label>
                                <span class="text-danger">*</span>
                                <Field
                                  type="text"
                                  id="phone"
                                  name="phone"
                                  v-model="detail.phone_number"
                                  class="form-control filled"
                                  rules="required:phone number|phone"
                                  :validateOnInput="true"
                                />
                              </div>
                              <ErrorMessage name="phone" class="validation-msg" />
                            </div>
                          </div>
                          <div
                            class="text-right mt-3 cursor-pointer"
                            v-on:click="$router.push('/reset-password')"
                          >
                            <a>{{ $t("label.Change Password") }}</a>
                          </div>
                          <div class="text-center mt-3">
                            <button id="submit-button" class="btn btn-brand-1 mb-3 px-5">
                              {{ $t("label.Save") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
</template>

<script>
// import Header from "@/components/header";
// import Footer from "@/components/footer";
import DeleteModal from "@/components/DeleteModal";
import $ from "jquery";
import { Form, Field, ErrorMessage } from "vee-validate";
// import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  name: "MyProfile",
  components: {
    // Header,
    // Footer,
    DeleteModal,
    Form,
    Field,
    ErrorMessage,
    // VueGoogleAutocomplete,
  },
  data() {
    return {
      id: "",
      file: undefined,
      detail: {},
      dataId: "",
    };
  },
  mounted() {
    this.meCall();
    this.getRoleList();
  },
  methods: {
    meCall() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.detail.avatar = res.info.avatar;
          this.detail.name = res.info.name;
          this.detail.email = res.info.email;
          this.detail.phone_number = res.info.phone_number;
          this.detail.dial_code = res.info.dial_code;
          this.detail.country_code = res.info.country_code;
        })
        .catch(() => {});
    },
    getRoleList() {
      this.$api
        .getAPI({
          _action: "roles",
        })
        .then((res) => {
          this.roleList = res.list;
        })
        .catch(() => {});
    },
    update() {
      this.$api
        .putAPI({
          _action: "me/update",
          _body: this.detail,
          _buttonId: "submit-button",
        })
        .then((res) => {
          if (res) {
            this.dataId = res.info.id;
            this.$notify({
              type: "success",
              text: res.info.message,
            });
            this.uploadImage();
          }
        })
        .catch(() => {});
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert(this.$t("label.Invalid file formate, please use jpeg or png file."));
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert(this.$t("label.File must be less then 10 MB"));
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    uploadImage() {
      if (!this.file) {
        this.$router.go(-1);
        return;
      }

      this.$api
        .uploadImageAPI({
          _action: "user/" + this.dataId + "/image",
          _key: "image",
          _file: this.file,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });

          this.$store.commit("setUser", this.detail);
          this.meCall();
        });
    },
    readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $("#img_hoder_1").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
  },
};
</script>

