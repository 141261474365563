<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row p-3 align-items-center">
                <div class="col-md-6 col-5">
                  <h2 class="page-title mb-md-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ $t("label.Manage Notifications") }}
                  </h2>
                </div>
              </div>
              <div
                class="row mb-md-4 mb-3 align-items-center justify-content-between filter-options"
              ></div>
              <div class="row mb-3">
                <div class="col-lg-6 col-md-4 d-md-flex mb-md-0 mb-2">
                  <div class="input-group page-cont-search border col-md-6 p-0">
                    <span class="input-group-text"><i class="bx bx-search"></i></span>
                    <input
                      type="text"
                      class="form-control"
                      style="height: 55px"
                      name=""
                      v-on:keyup.enter="getNotificationList(0)"
                      :placeholder="$t('label.search here')"
                      v-model="searchObj.keyword"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-7 text-right d-md-flex justify-content-end mb-3">
                  <button
                    type="button"
                    class="btn btn-brand-1 btn-sm cursor-pointer"
                    v-on:click="$router.push('/send-notification')"
                    v-if="access.can_create"
                  >
                    <i class="bx bx-plus"></i> {{ $t("label.Send Notification") }}
                  </button>
                </div>
              </div>
              <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
                <table class="table-style-1 table table-responsive" id="sorting_tbl">
                  <thead>
                    <tr class="row col 12">
                      <th class="col-1">{{ $t("label.S_No") }}</th>
                      <th class="col-2">{{ $t("label.Date & Time") }}</th>
                      <th class="col-2">{{ $t("label.Title") }}</th>
                      <th class="col-2">{{ $t("label.Text") }}</th>
                      <th class="col-1">{{ $t("label.Send By") }}</th>
                      <th class="col-4">{{ $t("label.Action") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="row col 12"
                      v-for="(data, index) in notificationList"
                      :key="index"
                    >
                      <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                      <td class="col-2">{{ data.created_at }}</td>
                      <td class="col-2">{{ data.title }}</td>
                      <td class="col-2">{{ data.message }}</td>

                      <td class="col-1">
                        <span class="text-warning">{{ $t("label.Admin") }}</span>
                      </td>
                      <td class="col-4">
                        <button
                          class="btn btn-brand-1 px-5 mr-2 cursor-pointer"
                          v-on:click="$router.push('/history/' + data.id)"
                          v-if="access.can_read"
                        >
                          {{ $t("label.View History") }}
                        </button>
                        <button
                          class="btn btn-action mr-2 mb-md-0 mb-2 text-danger cursor-pointer"
                          v-on:click="showDeletePopup(data.id)"
                          v-if="access.can_delete"
                        >
                          <i class="bx bxs-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ErrorComponent @retry="getNotificationList(1)" ref="errorComponent" />
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-lg-12 mt-3">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
</template>

<script>
import moment from "moment";
import ErrorComponent from "@/components/ErrorComponent";
import helperService from "../../services/HelperService";
// import { Form, Field, ErrorMessage } from "vee-validate";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "ManageAdmins",

  components: {
    DeleteModal,
    ErrorComponent,
    Pagination,
    //  Form,
    //  Field,
    // ErrorMessage
  },
  data() {
    return {
      notificationList: [],
      // products: {},
      searchObj: {
        keyword: "",
        offset: 0,
      },
      loader: true,
      currentPage: 1,
      itemsPerPage: 10,
      token: localStorage.getItem("access_token"),
      access: { can_create: 1, can_update: 1, can_read: 1, can_delete: 1 },
    };
  },
  mounted() {
    this.getNotificationList(1);
  },
  methods: {
    pageChange(page) {
      this.getNotificationList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getNotificationList(1);
    },
    getNotificationList(page, sortBy, orderBy) {
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.searchObj.sort_order = orderBy;
      }
      if (!page) {
        page = 1;
      }
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      // this.searchObj.row_per_page = this.itemsPerPage;

      this.notificationList = [];
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: "notifications",
          _body: this.searchObj,
        })
        .then((res) => {
          this.notificationList = res.list;
          this.access = res.manage_notifications;
          for (var k in res.list) {
            res.list.created_at = moment(res.list[k].created_at).format("DD/MM/YYYY");
            res.list.end_date = moment(res.list[k].end_date).format("DD/MM/YYYY");
          }
          this.searchObj.offset = res.next_offset;

          
          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
            if (res.count != null && res.count > 0 && this.access.can_read) {
              this.$refs.listPagination.setTotalCount(res.count);
            } else {
              this.$refs.listPagination.setTotalCount(0);
            }
          }

          if (this.$refs.errorComponent) {
            if (this.access.can_read == 0) {
              this.$refs.errorComponent.setData(res, this.$t("label.Sorry, you don't have permission"));
              this.$refs.errorComponent.updateLoader(false);
              return;
            }
            this.$refs.errorComponent.setData(res, this.$t("label.Notification Not Available"));
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    router(id) {
      console.log("router", id);
      this.$router.push("/edit-admin/" + id);
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        this.$t("label.Delete"),
        this.$t("label.Are you sure you want to delete"),
        id
      );
    },
    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "notification/" + id,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.deleteModal.closeModal();
          this.getNotificationList(0);
        })
        .catch(() => {});
    },

    sorting(sortBy, id) {
      var obj = helperService.sorting(sortBy, id);
      this.getNotificationList(0, obj.order_by, obj.sort_by);
    },
  },
};
</script>

