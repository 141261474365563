<template>
  <div>
    <div v-if="showMessage" class="text-center">
      <div class="w-50 d-inline-block pt-5 pb-5 mt-5 mb-5">
        <h3 class="mb-4">
          <strong>{{ title }}</strong>
        </h3>
        <p v-if="subtitle">{{ subtitle }}</p>
        <a v-if="subtitle" @click="onClick()" href="javascript: void(0);">Please Retry</a>
      </div>
    </div>
    <div
      v-show="loader"
      class="text-center"
      style="background: white; border-radius: 0 0 16px 16px"
    >
      <img src="/images/loader.gif" style="width: 100px; height: 100px" />
    </div>
  </div>
</template>
<script>
export default {
  name: "ErrorComponent",
  data() {
    return {
      showMessage: false,
      loader: true,
      title: "",
      subTitle: "",
    };
  },
  methods: {
    onClick() {
      this.updateShowMessage();
      this.$emit("retry");
    },
    updateShowMessage() {
      this.showMessage = !this.showMessage;
    },
    setTitle(title) {
      if (title) {
        this.showMessage = true;
        this.setSubtitle();
        this.title = title;
      } else {
        this.setSubtitle(true);
        this.title = "Error";
        this.updateShowMessage();
      }
    },
    isShowMessage() {
      return this.showMessage;
    },
    setSubtitle(isShowSubtitle) {
      this.subtitle = isShowSubtitle ? "Looks like something went wrong!" : undefined;
    },
    updateLoader(isShowLoader) {
      if (isShowLoader) {
        this.showMessage = false;
      }
      this.loader = isShowLoader;
    },
    setData(res, title) {
      if (res) {
        if (!res.list || res.list.length <= 0) {
          this.setTitle(title);
        }
      } else {
        this.setTitle();
      }
      this.updateLoader(false);
    },
  },
};
</script>
