<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row p-3 align-items-center">
                <div class="col-md-6 col-5">
                  <h2 class="page-title mb-md-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ $t("label.Manage Categories") }}
                  </h2>
                </div>
              </div>

              <ul class="nav nav-pills mb-3 tab-style-1" id="pills-tab" role="tablist">
                <li class="col-6 text-center nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pending_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'PRODUCT' }"
                    v-on:click="changeTab('PRODUCT')"
                    style="cursor: pointer"
                    >{{ $t("label.Products") }}</a
                  >
                </li>
                <li class="col-6 text-center nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="accepted_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'SERVICE' }"
                    v-on:click="changeTab('SERVICE')"
                    style="cursor: pointer"
                    >{{ $t("label.Services") }}</a
                  >
                </li>
              </ul>
              <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pending_orders"
                  role="tabpanel"
                  aria-labelledby="pending_orders-tab"
                >
                  <div class="row mb-3">
                    <div class="col-lg-6 col-md-4 d-md-flex mb-md-0 mb-2">
                      <div class="input-group page-cont-search border col-md-6 p-0">
                        <span class="input-group-text"><i class="bx bx-search"></i></span>
                        <input
                          type="text"
                          class="form-control"
                          style="height: 55px"
                          name=""
                          v-on:keyup.enter="changeTab(this.currentTab)"
                          :placeholder="$t('label.search here')"
                          v-model="searchObj.keyword"
                        />
                      </div>
                    </div>
                    <div
                      class="col-md-6 col-7 text-right d-md-flex justify-content-end mb-3"
                    >
                      <button
                        type="button"
                        class="btn btn-brand-1 btn-sm"
                        v-on:click="$router.push('/add-category?type=' + this.currentTab)"
                        v-if="sub_cat_access.can_create && cat_access.can_create"
                      >
                        <i class="bx bx-plus"></i> {{ $t("label.Add") }}
                      </button>
                    </div>
                  </div>
                  <table class="table table-style-1 table-responsive" id="sorting_tbl">
                    <thead>
                      <tr>
                        <th>{{ $t("label.S_No") }}</th>
                        <th>{{ $t("label.Category Name") }}</th>
                        <th>{{ $t("label.Action") }}</th>
                      </tr>
                    </thead>
                    <tbody v-if="this.currentTab == 'PRODUCT'">
                      <tr v-for="(data, index) in list" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ data.title }}</td>
                        <td>
                          <button
                            class="btn btn-brand-1 px-5 mr-2"
                            v-on:click="
                              $router.push(
                                '/manage-sub_categories/' +
                                  data.id +
                                  '?type=' +
                                  this.currentTab
                              )
                            "
                          >
                           {{ $t("label.View Sub-Category") }}
                          </button>
                          <button
                            class="btn btn-action mr-2 mb-md-0 mb-2 text-success"
                            v-if="sub_cat_access.can_delete || cat_access.can_delete"
                            v-on:click="
                              $router.push(
                                '/edit-category/' + data.id + '?type=' + this.currentTab
                              )
                            "
                            title="Edit"
                          >
                            <i class="bx bxs-pencil"></i>
                          </button>
                          <button
                            class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                            v-on:click="showDeletePopup(data.id)"
                            title="Delete"
                            v-if="cat_access.can_delete || sub_cat_access.can_delete"
                          >
                            <i class="bx bxs-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>

                    <tbody v-if="this.currentTab == 'SERVICE'">
                      <tr v-for="(data, index) in list" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ data.title }}</td>
                        <td>
                          <button
                            class="btn btn-action mr-2 mb-md-0 mb-2 text-success"
                            v-if="sub_cat_access.can_delete || cat_access.can_delete"
                            v-on:click="
                              $router.push(
                                '/edit-category/' + data.id + '?type=' + this.currentTab
                              )
                            "
                            title="Edit"
                          >
                            <i class="bx bxs-pencil"></i>
                          </button>
                          <button
                            class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                            v-on:click="showDeletePopup(data.id)"
                            title="Delete"
                            v-if="sub_cat_access.can_delete || cat_access.can_delete"
                          >
                            <i class="bx bxs-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ErrorComponent @retry="getList(1)" ref="errorComponent" />
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-lg-12 mt-3">
                  <div class="page-bottom-pagination text-right">
                    <Pagination
                      @page-change="pageChange"
                      @items-per-page-change="itemsPerPageChange"
                      ref="listPagination"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
</template>
<script>
// import moment from "moment";
import DeleteModal from "@/components/DeleteModal";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "ManageCategories",

  components: {
    ErrorComponent,
    Pagination,
    DeleteModal,
  },

  data() {
    return {
      list: [],
      currentTab: "PRODUCT",
      searchObj: {
        keyword: "",
        offset: 0,
      },
      currentPage: 1,
      loader: true,
      itemsPerPage: 10,
      cat_access: { can_create: 1, can_update: 1, can_read: 1, can_delete: 1 },
      sub_cat_access: { can_create: 1, can_update: 1, can_read: 1, can_delete: 1 },
      url: "",
    };
  },

  mounted() {
    this.changeTab("PRODUCT");
  },

  methods: {
    changeTab(tab) {
      this.url = "";
      this.currentTab = tab;
      if (tab == "PRODUCT") {
        this.url = "product";
        this.getList(1);
      } else if (tab == "SERVICE") {
        this.url = "service";
        this.getList(1);
      }
    },
    pageChange(offset) {
      this.getList(offset);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    getList(page, sortBy, orderBy) {
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.searchObj.sort_order = orderBy;
      }
      if (!page) {
        page = 1;
      }
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      // this.searchObj.row_per_page = this.itemsPerPage;

      this.list = [];
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: this.url + "/categories",
          _body: this.searchObj,
        })
        .then((res) => {
          this.list = res.list;
          this.searchObj.offset = res.next_offset;

          this.cat_access = res.manage_categories;
          this.sub_cat_access = res.manage_sub_categories;

          this.cat_access.can_create = this.cat_access.can_read;
          this.sub_cat_access.can_create = this.sub_cat_access.can_read;
          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
            if (res.count != null && res.count > 0 && this.cat_access.can_read) {
              this.$refs.listPagination.setTotalCount(res.count);
            } else {
              this.$refs.listPagination.setTotalCount(0);
            }
          }

          if (this.$refs.errorComponent) {
            if (this.cat_access.can_read == 0 || this.sub_cat_access.can_read == 0) {
              this.$refs.errorComponent.setData(res, this.$t("label.Sorry, you don't have permission"));
              this.$refs.errorComponent.updateLoader(false);
              return;
            }
            this.$refs.errorComponent.setData(res, this.$t("label.Category Not Available"));
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        this.$t("label.Delete"),
        this.$t("label.Are you sure you want to delete"),
        id
      );
    },
    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "product/category/" + id,
          // _buttonId: "delete-button",
        })
        .then((res) => {
          this.getList(1);
          this.$refs.deleteModal.closeModal();
          this.getServiceCategoryList(1);
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
  }
};
</script>

