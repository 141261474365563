<template>
  <div class="d-lg-flex no-right-sidebar" id="main-wraper">
    <div class="page-wrapper no-right-sidebar">
      <div class="page-content">
        <div class="row mb-4 justify-content-center">
          <div class="col-lg-12">
            <div class="row p-3 align-items-center text-dark">
              <div class="col-md-6 col-5">
                <h2 class="page-title mb-md-0">
                  <a
                    href="javascript:void(0)"
                    v-on:click="$router.go(-1)"
                    class="back-arrow"
                  >
                    <i class="bx bx-arrow-back"></i
                  ></a>

                  {{ id ? $t("label.Edit") : $t("label.Add") }}
                  {{ $t("label.Sub Category") }}
                </h2>
              </div>
            </div>
            <div
              class="card card-body content-card mb-4 rounded-l border-0 shadow-sm"
              style="width: 120%"
            >
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <form class="form-style">
                    <Form class="form-style floating-form" @submit="save()">
                      <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-5 mb-4 text-center">
                          <div class="mb-3">
                            <img
                              :src="
                                detail.image && detail.image != null
                                  ? detail.image
                                  : '/images/placeholder.png'
                              "
                              class="rounded-circle border border-light"
                              style="border-width: 3px !important"
                              alt="image thumb"
                              id="preview"
                              width="130"
                              height="130"
                            />
                          </div>
                          <button
                            type="button"
                            class="btn px-3 upload-img-merge-btn btn-secondary w-75 text-capitalize"
                            for="pickFile"
                          >
                            {{ id ? "Change" : "Add" }} {{ $t("label.photo") }}
                            <input
                              type="file"
                              class="btn upload-img"
                              id="pickFile"
                              @change="selectedFile($event)"
                            />
                          </button>
                        </div>
                      </div>
                      <div class="row justify-content-center mb-5">
                        <div class="col-md-10">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="title"
                                  name="title"
                                  v-model="detail.title"
                                  class="form-control filled"
                                  rules="required:title"
                                  :validateOnInput="true"
                                />
                                <label for="title">{{ $t("label.Title") }}</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="fname" class="validation-msg" />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group">
                                <textarea
                                  type="text"
                                  id="description"
                                  name="description"
                                  v-model="detail.description"
                                  class="form-control filled"
                                  rules="required:description"
                                  :validateOnInput="true"
                                />
                                <label for="description">{{
                                  $t("label.Description")
                                }}</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="description" class="validation-msg" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center mt-3">
                        <button
                          type="button"
                          class="btn btn-brand-1 mb-3 mr-2 px-5"
                          v-on:click="$router.go(-1)"
                        >
                          {{ $t("label.Cancel") }}
                        </button>
                        <button
                          class="btn btn-brand-1 mb-3 px-5"
                          id="save-button"
                          type="submit"
                        >
                          {{ $t("label.Save") }}
                        </button>
                      </div>
                    </Form>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "AddCategory",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      type: this.$route.query.type,
      file: undefined,
      parent_id: this.$route.query.parentId,
      detail: {
        parent_category_id: this.$route.query.parent_category_id,
      },
      category_id: "",
      url: "",
    };
  },
  mounted() {
    if (this.id) {
      if (this.type == "PRODUCT") {
        this.url = "product";
        this.getDetail();
      } else if (this.type == "SERVICE") {
        this.url = "service";
        this.getDetail();
      }
    } else {
      if (this.type == "PRODUCT") {
        this.url = "product";
      } else if (this.type == "SERVICE") {
        this.url = "service";
      }
    }
  },
  methods: {
    save() {
      if (this.id) {
        var obj = {};
        obj.title = this.detail.title;
        obj.description = this.detail.description;
        obj.parent_category_id = this.detail.parent_category_id;
        this.$api
          .putAPI({
            _action: this.url + "/category/" + this.id,
            _body: obj,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.uploadImage(res.message);
          })
          .catch(() => {});
      } else {
        var obj2 = {};
        obj2.title = this.detail.title;
        obj2.description = this.detail.description;
        obj2.parent_category_id = this.parent_id;
        this.$api
          .postAPI({
            _action: this.url + "/category",
            _body: obj2,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.category_id = res.id;
            this.id = res.id;
            this.uploadImage(res.message);
          })
          .catch(() => {});
      }
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert(this.$t("label.Invalid file formate, please use jpeg or png file."));
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert(this.$t("label.File must be less then 10 MB"));
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    uploadImage(message) {
      if (!this.file) {
        this.$notify({
          type: "success",
          text: message,
        });
        this.$router.go(-1);
        return;
      }
      if (this.id) {
        this.category_id = this.id;
      }
      this.$api
        .uploadImageAPI({
          _action: "category/image/" + this.category_id,
          _key: "image",
          _file: this.file,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$router.go(-1);
        })
        .catch(() => {});
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: this.url + "/category/" + this.id,
        })
        .then((res) => {
          this.detail = res;
        })
        .catch(() => {});
    },
  },
};
</script>

