<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row p-3 align-items-center">
                <div class="col-md-6 col-5">
                  <h2 class="page-title mb-md-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ $t("label.Manage Helps") }}
                  </h2>
                </div>
              </div>
              <div
                class="row mb-md-4 mb-3 align-items-center justify-content-between filter-options"
              ></div>
              <div class="row mb-3">
                <div class="col-lg-6 col-md-4 d-md-flex mb-md-0 mb-2">
                  <div class="input-group page-cont-search border col-md-6 p-0">
                    <span class="input-group-text"><i class="bx bx-search"></i></span>
                    <input
                      type="text"
                      class="form-control"
                      style="height: 55px"
                      name=""
                      v-on:keyup.enter="getAdminList(0)"
                      :placeholder="$t('label.search here')"
                      v-model="searchObj.keyword"
                    />
                  </div>
                </div>
              </div>
              <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
                <table class="table-style-1 table table-responsive" id="sorting_tbl">
                  <thead>
                    <tr>
                      <th>{{ $t("label.S_No") }}</th>
                      <th>{{ $t("label.Name") }}</th>
                      <th>{{ $t("label.Email") }}</th>
                      <th>{{ $t("label.Contact Number") }}</th>
                      <th>{{ $t("label.Message") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in helpList" :key="index">
                      <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                      <td>{{ data.user.name }}</td>
                      <td>{{ data.user.email }}</td>
                      <td>{{ data.user.phone_number }}</td>
                      <td>{{ data.query }}</td>
                    </tr>
                  </tbody>
                </table>
                <ErrorComponent @retry="getHelpList(1)" ref="errorComponent" />
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-lg-12 mt-3">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
</template>

<script>
import ErrorComponent from "@/components/ErrorComponent";
import helperService from "../../services/HelperService";
// import { Form, Field, ErrorMessage } from "vee-validate";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "ManageFAQ's",

  components: {
    DeleteModal,
    ErrorComponent,
    Pagination,
    //  Form,
    //  Field,
    // ErrorMessage
  },
  data() {
    return {
      helpList: [],
      // products: {},
      searchObj: {
        keyword: "",
        offset: 0,
      },
      loader: true,
      currentPage: 1,
      itemsPerPage: 10,
      token: localStorage.getItem("access_token"),
    };
  },
  mounted() {
    this.getHelpList(1);
  },
  methods: {
    pageChange(page) {
      this.getHelpList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getHelpList(1);
    },
    getHelpList(page, sortBy, orderBy) {
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.searchObj.sort_order = orderBy;
      }
      if (!page) {
        page = 1;
      }
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      // this.searchObj.row_per_page = this.itemsPerPage;

      this.helpList = [];
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: "contact-supports",
          _body: this.searchObj,
        })
        .then((res) => {
          this.helpList = res.list;

          this.searchObj.offset = res.next_offset;

          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
            if (res.count != null && res.count > 0) {
              this.$refs.listPagination.setTotalCount(res.count);
            } else {
              this.$refs.listPagination.setTotalCount(0);
            }
          }

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, this.$t("label.Record Not Available"));
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },

    sorting(sortBy, id) {
      var obj = helperService.sorting(sortBy, id);
      this.getHelpList(1, obj.order_by, obj.sort_by);
    },
  },
};
</script>

