<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row p-3 align-items-center">
                <div class="col-md-6 col-5">
                  <h2 class="page-title mb-md-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ $t("label.Edit Customer") }}
                  </h2>
                </div>
              </div>

              <ul class="nav nav-pills mb-3 tab-style-1" id="pills-tab" role="tablist">
                <li class="col-4 text-center nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pending_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'BASIC' }"
                    v-on:click="changeTab('BASIC')"
                    style="cursor: pointer"
                    >{{ $t("label.Basic Information") }}</a
                  >
                </li>
                <li class="col-4 text-center nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pending_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'PRODUCT' }"
                    v-on:click="changeTab('PRODUCT')"
                    style="cursor: pointer"
                    >{{ $t("label.Product Orders") }}</a
                  >
                </li>
                <li class="col-4 text-center nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="accepted_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'SERVICE' }"
                    v-on:click="changeTab('SERVICE')"
                    style="cursor: pointer"
                    >{{ $t("label.Service Orders") }}</a
                  >
                </li>
              </ul>
              <div
                class="card card-body content-card mb-4 rounded-l border-0 shadow-sm"
                style="width: 100%"
                v-if="this.currentTab == 'BASIC'"
              >
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade active show"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div class="form-style floating-form">
                      <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-5 mb-4 text-center">
                          <div class="mb-3">
                            <img
                              :src="
                                detail.avatar && detail.avatar != null
                                  ? detail.avatar
                                  : '/images/profile.png'
                              "
                              class="rounded-circle border border-light"
                              style="border-width: 3px !important"
                              alt="image thumb"
                              id="preview"
                              width="130"
                              height="130"
                            />
                          </div>
                          <button
                            type="button"
                            class="btn px-3 upload-img-merge-btn btn-secondary w-75 text-capitalize"
                            for="pickFile"
                          >
                            {{ $t("label.Change photo") }}
                            <input
                              type="file"
                              class="btn upload-img"
                              id="pickFile"
                              @change="selectedFile($event)"
                            />
                          </button>
                        </div>
                      </div>
                      <div class="row justify-content-center mb-3">
                        <div class="col-md-10">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="fname"
                                  name="fname"
                                  v-model="detail.name"
                                  class="form-control filled"
                                  rules="required:name"
                                  :validateOnInput="true"
                                />
                                <label for="fname">{{ $t("label.Name") }}</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="fname" class="validation-msg" />
                            </div>
                            <div class="col-md-6">
                              <div class="floating-form-group">
                                <Field
                                  type="email"
                                  id="email"
                                  name="email"
                                  v-model="detail.email"
                                  class="form-control filled"
                                  rules="email"
                                  :validateOnInput="true"
                                />
                                <label for="email">{{ $t("label.Email") }}</label>
                              </div>
                              <ErrorMessage name="email" class="validation-msg" />
                            </div>
                            <div class="col-md-6">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="phone"
                                  name="phone"
                                  v-model="detail.phone_number"
                                  class="form-control filled"
                                  rules="required:phone number|phone"
                                  :validateOnInput="true"
                                />
                                <label for="phone">{{ $t("label.Phone") }}</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="phone" class="validation-msg" />
                            </div>
                            <!-- <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="address"
                                  name="address"
                                  v-model="detail.address"
                                  class="form-control filled"
                                  rules="required:address"
                                  :validateOnInput="true"
                                />
                                <label for="address">Address</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="address" class="validation-msg" />
                            </div> -->
                            <!-- <div class="col-md-12">
                              <label for="address">Work Address</label>
                              <span class="text-danger">*</span>
                              <div class="form-group d-flex flex-row">
                                <Field
                                  type="text"
                                  id="address"
                                  name="address"
                                  v-model="detail.address"
                                  class="form-control"
                                  rules="required:address"
                                  :validateOnInput="true"
                                  style="border-radius: 5px 0px 0px 5px !important"
                                />
                                <a
                                  v-show="detail.address != ''"
                                  :href="'http://maps.google.com/?q=' + detail.address"
                                  class=""
                                  target="_blank"
                                >
                                  <button
                                    class="btn"
                                    style="
                                      background-color: #00bb59;
                                      border-radius: 0px 5px 5px 0px !important;
                                    "
                                  >
                                    View
                                  </button>
                                </a>
                              </div>
                              <ErrorMessage name="address" class="validation-msg" />
                            </div> -->
                            <div class="col-md-12 d-flex flex-row">
                              <div class="floating-form-group with-icon">
                                <vue-google-autocomplete
                                  ref="address1"
                                  id="address1"
                                  classname="form-control filled"
                                  :placeholder="$t('label.Type Here')"
                                  v-model="address"
                                  v-on:placechanged="getWorkAddressData"
                                  types=""
                                >
                                </vue-google-autocomplete>

                                <label for="city">{{ $t("label.Work Address") }}</label>
                                <i class="bx bx-map icon"></i>
                                <span class="text-danger">*</span>
                              </div>
                              <a
                                :href="'http://maps.google.com/?q=' + detail.address"
                                class=""
                                target="_blank"
                              >
                                <button
                                  class="btn"
                                  style="
                                    padding-top: 11px;
                                    border-radius: 0px 5px 5px 0px !important;
                                  "
                                >
                                {{ $t("label.View") }}
                                </button>
                              </a>
                              <!-- {{ detail.address }} -->
                              <ErrorMessage name="city" class="validation-msg" />
                            </div>
                          </div>
                          <div class="d-flex flex-row">
                            <div class="col-lg-6 col-md-6"></div>
                            <div class="text-right col-lg-6 col-md-6">
                              <a
                                class="cursor-pointer"
                                v-on:click="
                                  $router.push('/supplier/change-password/' + detail.id)
                                "
                                >{{ $t("label.Change Password") }}</a
                              >
                            </div>
                          </div>
                          <div class="text-center mt-3">
                            <button
                              id="submit-button"
                              class="btn btn-brand-1 mb-3 px-5 mr-2"
                              v-on:click="update()"
                            >
                              {{ $t("label.Save") }}
                            </button>
                            <button
                              type="button"
                              id="cancel-button"
                              class="btn btn-brand-1 mb-3 px-5"
                              v-on:click="$router.go(-1)"
                            >
                              {{ $t("label.Cancel") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row mb-4 justify-content-center"
                v-if="this.currentTab == 'PRODUCT'"
              >
                <div class="col-lg-12">
                  <ul
                    class="nav nav-pills mb-3 tab-style-1"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pending_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab2 == 'PENDING' }"
                        v-on:click="changeTab2('PENDING')"
                        style="cursor: pointer"
                        >{{ $t("label.Pending Orders") }}</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="accepted_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab2 == 'ACCEPTED' }"
                        v-on:click="changeTab2('ACCEPTED')"
                        style="cursor: pointer"
                        >{{ $t("label.Accepted Orders") }}</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="rejected_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab2 == 'REJECTED' }"
                        v-on:click="changeTab2('REJECTED')"
                        style="cursor: pointer"
                        >{{ $t("label.Rejected Orders") }}</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="delivered_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab2 == 'COMPLETED' }"
                        v-on:click="changeTab2('COMPLETED')"
                        style="cursor: pointer"
                        >{{ $t("label.Delivered Orders") }}</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="cancelled_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab2 == 'CANCELLED' }"
                        v-on:click="changeTab2('CANCELLED')"
                        style="cursor: pointer"
                        >{{ $t("label.Cancelled Orders") }}</a
                      >
                    </li>
                  </ul>
                  <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pending_orders"
                      role="tabpanel"
                      aria-labelledby="pending_orders-tab"
                    >
                      <div class="row mb-3">
                        <div class="col-lg-6 col-md-4 d-md-flex mb-md-0 mb-2">
                          <div class="input-group page-cont-search border col-md-6">
                            <span class="input-group-text"
                              ><i class="bx bx-search"></i
                            ></span>
                            <input
                              type="text"
                              class="form-control"
                              name=""
                              v-on:keyup.enter="getProductList(1)"
                              :placeholder="$t('label.search here')"
                              v-model="searchObj.keyword"
                            />
                          </div>
                        </div>
                        <!-- <div class="col-md-3 mb-md-0 mb-2">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                ><i class="bx bx-calendar mr-1"></i> From</span
                              >
                            </div>
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                ><i class="bx bx-calendar mr-1"></i> To</span
                              >
                            </div>
                            <input type="text" class="form-control" />
                          </div>
                        </div> -->
                      </div>
                      <table
                        class="table table-style-1 table-responsive"
                        id="sorting_tbl"
                      >
                        <thead>
                          <tr>
                            <th>{{ $t("label.Order ID") }}</th>
                            <th>{{ $t("label.Date & Time") }}</th>
                            <th>{{ $t("label.Preferred Date") }}</th>
                            <th>{{ $t("label.Customer Name") }}</th>

                            <th>{{ $t("label.Mobile Number") }}</th>
                            <th>{{ $t("label.Email") }}</th>
                            <th>{{ $t("label.Location") }}</th>
                            <th>{{ $t("label.Price") }}</th>
                            <th>{{ $t("label.Action") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in productOrder" :key="index">
                            <td>{{ data.shipping.order_id }}</td>
                            <td>{{ data.created_at }}</td>
                            <td>
                              <div>{{ data.preferred_date }}</div>
                          <span v-if="data.preferred_time == '06_AM_TO_10_AM'">{{
                            $t("label.06 AM TO 10 AM")
                          }}</span>
                          <span v-if="data.preferred_time == '10_AM_TO_02_PM'">{{
                            $t("label.10 AM TO 02 PM")
                          }}</span>
                          <span v-if="data.preferred_time == '02_PM_TO_06_PM'">{{
                            $t("label.02 PM TO 06 PM")
                          }}</span>
                          <span v-if="data.preferred_time == '06_PM_TO_10_PM'">{{
                            $t("label.06 PM TO 10 PM")
                          }}</span>
                            </td>
                            <td>{{ data.name }}</td>

                            <td>{{ data.phone_number }}</td>
                            <td>{{ data.email }}</td>
                            <td>{{ data.shipping.address }}</td>
                            <td>{{ data.net_amount }}</td>
                            <td>
                              <button
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                                v-on:click="
                                  $router.push(
                                    '/product-order-detail/' + data.shipping.order_id
                                  )
                                "
                              >
                                <i class="bx bx-show"></i>
                              </button>
                              <button
                                v-show="currentTab == 'PENDING'"
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-success"
                                v-on:click="
                                  updateOrderStatus(data.shipping.order_id, 'ACCEPTED')
                                "
                              >
                                <i class="bx bxs-check-circle"></i>
                              </button>
                              <button
                                v-show="currentTab == 'PENDING'"
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                                v-on:click="
                                  updateOrderStatus(data.shipping.order_id, 'REJECTED')
                                "
                              >
                                <i class="bx bxs-x-circle"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <ErrorComponent @retry="getProductList(1)" ref="errorComponent" />
                  </div>
                  <div class="row mb-4">
                    <div class="col-lg-12 mt-3">
                      <div class="page-bottom-pagination text-right">
                        <Pagination
                          @page-change="pageChange"
                          @items-per-page-change="itemsPerPageChange"
                          ref="listPagination"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <ErrorComponent
                  v-if="this.currentTab == 'SERVICE'"
                  @retry="getServiceCategoryList(0)"
                  ref="errorSComponent"
                />
                <ErrorComponent
                  v-if="this.currentTab == 'PRODUCT'"
                  @retry="getProductCategoryList(0)"
                  ref="errorPComponent" -->

                <div class="row mb-4">
                  <div class="col-lg-12 mt-3">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row mb-4 justify-content-center"
                v-if="this.currentTab == 'SERVICE'"
              >
                <div class="col-lg-12">
                  <ul
                    class="nav nav-pills mb-3 tab-style-1"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pending_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab3 == 'NEW_VISIT' }"
                        v-on:click="changeTab3('NEW_VISIT')"
                        style="cursor: pointer"
                        >{{ $t("label.New Visit") }}</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pending_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab3 == 'OUTGOING' }"
                        v-on:click="changeTab3('OUTGOING')"
                        style="cursor: pointer"
                        >{{ $t("label.Outgoing") }}</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pending_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab3 == 'VISIT_COMPLETE' }"
                        v-on:click="changeTab3('VISIT_COMPLETE')"
                        style="cursor: pointer"
                      >
                      {{ $t("label.Visit Complete") }}
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pending_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab3 == 'PENDING_NEW_WORK' }"
                        v-on:click="changeTab3('PENDING_NEW_WORK')"
                        style="cursor: pointer"
                        >{{ $t("label.Pending New Work") }}
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pending_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab3 == 'NEW_WORK_UNDER_PROGRESS' }"
                        v-on:click="changeTab3('NEW_WORK_UNDER_PROGRESS')"
                        style="cursor: pointer"
                        >{{ $t("label.New Work Under Progress") }}
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pending_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab3 == 'NEW_WORK_COMPLETE' }"
                        v-on:click="changeTab3('NEW_WORK_COMPLETE')"
                        style="cursor: pointer"
                        >{{ $t("label.New Work Complete") }}
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pending_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab3 == 'REJECTED' }"
                        v-on:click="changeTab3('REJECTED')"
                        style="cursor: pointer"
                        >{{ $t("label.Rejected") }}
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pending_orders"
                      role="tabpanel"
                      aria-labelledby="pending_orders-tab"
                    >
                      <div class="row mb-3">
                        <div class="col-lg-6 col-md-4 d-md-flex mb-md-0 mb-2">
                          <div class="input-group page-cont-search border col-md-6">
                            <span class="input-group-text"
                              ><i class="bx bx-search"></i
                            ></span>
                            <input
                              type="text"
                              class="form-control"
                              name=""
                              v-on:keyup.enter="getServiceList(1)"
                              :placeholder="$t('label.search here')"
                              v-model="searchObj.keyword"
                            />
                          </div>
                        </div>
                        <!-- <div class="col-md-3 mb-md-0 mb-2">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                ><i class="bx bx-calendar mr-1"></i> From</span
                              >
                            </div>
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                ><i class="bx bx-calendar mr-1"></i> To</span
                              >
                            </div>
                            <input type="text" class="form-control" />
                          </div>
                        </div> -->
                      </div>
                      <table
                        class="table table-style-1 table-responsive"
                        id="sorting_tbl"
                      >
                        <thead>
                          <tr>
                            <th>{{ $t("label.Sr_No") }}</th>
                            <th>{{ $t("label.Order ID") }}</th>
                            <th>{{ $t("label.Date & Time") }}</th>
                            <th>{{ $t("label.Preferred Date") }}</th>
                            <th>{{ $t("label.Customer Name") }}</th>

                            <th>{{ $t("label.Mobile Number") }}</th>
                            <th>{{ $t("label.Email") }}</th>
                            <th>{{ $t("label.Location") }}</th>
                            <th>{{ $t("label.Price") }}</th>
                            <th>{{ $t("label.Action") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in serviceOrder" :key="index">
                            <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                            <td>{{ data.shipping.order_id }}</td>
                            <td>{{ data.created_at }}</td>
                            <td>
                              <div>{{ data.preferred_date }}</div>
                          <span v-if="data.preferred_time == '06_AM_TO_10_AM'">{{
                            $t("label.06 AM TO 10 AM")
                          }}</span>
                          <span v-if="data.preferred_time == '10_AM_TO_02_PM'">{{
                            $t("label.10 AM TO 02 PM")
                          }}</span>
                          <span v-if="data.preferred_time == '02_PM_TO_06_PM'">{{
                            $t("label.02 PM TO 06 PM")
                          }}</span>
                          <span v-if="data.preferred_time == '06_PM_TO_10_PM'">{{
                            $t("label.06 PM TO 10 PM")
                          }}</span>
                            </td>
                            <td>{{ data.name }}</td>

                            <td>{{ data.phone_number }}</td>
                            <td>{{ data.email }}</td>
                            <td>{{ data.shipping.address }}</td>
                            <td>{{ data.net_amount }}</td>
                            <td>
                              <button
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                                v-on:click="
                                  $router.push(
                                    '/product-order-detail/' + data.shipping.order_id
                                  )
                                "
                              >
                                <i class="bx bx-show"></i>
                              </button>
                              <button
                                v-show="currentTab3 == 'PENDING'"
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-success"
                                v-on:click="
                                  updateOrderStatus(data.shipping.order_id, 'ACCEPTED')
                                "
                              >
                                <i class="bx bxs-check-circle"></i>
                              </button>
                              <button
                                v-show="currentTab3 == 'PENDING'"
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                                v-on:click="
                                  updateOrderStatus(data.shipping.order_id, 'REJECTED')
                                "
                              >
                                <i class="bx bxs-x-circle"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <ErrorComponent @retry="getServiceList(1)" ref="errorComponent" />
                  </div>
                  <div class="row mb-4">
                    <div class="col-lg-12 mt-3">
                      <div class="page-bottom-pagination text-right">
                        <Pagination
                          @page-change="pageServiceChange"
                          @items-per-page-change="itemsServicePerPageChange"
                          ref="listPagination"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
</template>
<script>
import { Field, ErrorMessage } from "vee-validate";
import moment from "moment";
import $ from "jquery";
import DeleteModal from "@/components/DeleteModal";
import ErrorComponent from "@/components/ErrorComponent";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "ManageCategories",

  components: {
    ErrorComponent,
    Pagination,
    DeleteModal,
    Field,
    ErrorMessage,
    VueGoogleAutocomplete,
  },

  data() {
    return {
      address: "",
      id: this.$route.params.id,
      file: undefined,
      serviceOrder: [],
      productOrder: [],
      currentTab: "BASIC",
      currentTab2: "PENDING",
      currentTab3: "NEW_VISIT",
      searchObj: {
        keyword: "",
        order_type: "PRODUCT",
        status: "PENDING_AT_SUPPLIER",
        offset: 0,
      },
      detail: {
        address: "",
        dial_code: "+91",
        country_code: "IN",
      },
      currentPage: 1,
      loader: true,
      itemsPerPage: 10,
    };
  },

  mounted() {
    this.getCustomerDetail();
    this.$refs.address1.focus();
  },

  methods: {
    getWorkAddressData: function (addressData, placeResultData) {
      this.address = addressData;
      console.log("formatted Address", placeResultData.formatted_address);
      this.detail.latitude = addressData.latitude;
      this.detail.longitude = addressData.longitude;
      this.detail.address = placeResultData.formatted_address;

      console.log("address list updated", this.detail.address);
    },
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "PRODUCT") {
        this.searchObj.status = "PENDING";
        this.searchObj.order_type = "PRODUCT";
        this.getProductList(1);
      } else if (tab == "SERVICE") {
        this.searchObj.status = "PENDING";
        this.searchObj.order_type = "SERVICE";
        this.getServiceList(1);
      } else if (tab == "BASIC") {
        this.getCustomerDetail(0);
      }
    },
    changeTab2(tab) {
      this.currentTab2 = tab;
      if (tab == "PENDING") {
        this.searchObj.status = "PENDING";
        this.searchObj.order_type = "PRODUCT";
        this.getProductList(1);
      } else if (tab == "ACCEPTED") {
        this.searchObj.status = "ACCEPTED";
        this.searchObj.order_type = "PRODUCT";
        this.getProductList(1);
      } else if (tab == "CANCELLED") {
        this.searchObj.status = "CANCELLED";
        this.searchObj.order_type = "PRODUCT";
        this.getProductList(1);
      } else if (tab == "REJECTED") {
        this.searchObj.status = "REJECTED";
        this.searchObj.order_type = "PRODUCT";
        this.getProductList(1);
      } else if (tab == "COMPLETED") {
        this.searchObj.status = "COMPLETED";
        this.searchObj.order_type = "PRODUCT";
        this.getProductList(1);
      }
    },
    changeTab3(tab) {
      this.currentTab3 = tab;
      if (tab == "NEW_VISIT") {
        this.searchObj.status = "PENDING_AT_SUPPLIER";
        this.searchObj.prev_parent_id = null;
        this.searchObj.customer_id = this.id;
        this.searchObj.order_type = "SERVICE";
        this.getServiceList(1);
      } else if (tab == "OUTGOING") {
        this.searchObj.status = "ACCEPTED";
        this.searchObj.prev_parent_id = null;
        this.searchObj.customer_id = this.id;
        this.searchObj.order_type = "SERVICE";
        this.getServiceList(1);
      } else if (tab == "VISIT_COMPLETE") {
        this.searchObj.status = "COMPLETED";
        this.searchObj.prev_parent_id = null;
        this.searchObj.customer_id = this.id;
        this.searchObj.order_type = "SERVICE";
        this.getServiceList(1);
      } else if (tab == "PENDING_NEW_WORK") {
        this.searchObj.status = "PENDING";
        // this.searchObj.prev_parent_id = null;
        this.searchObj.customer_id = this.id;
        this.searchObj.order_type = "SERVICE";
        this.getServiceList(1);
      } else if (tab == "NEW_WORK_UNDER_PROGRESS") {
        // this.searchObj.prev_parent_id = null;
        this.searchObj.customer_id = this.id;
        this.searchObj.status = "ACCEPTED";
        this.searchObj.order_type = "SERVICE";
        this.getServiceList(1);
      } else if (tab == "NEW_WORK_COMPLETE") {
        // this.searchObj.prev_parent_id = null;
        this.searchObj.customer_id = this.id;
        this.searchObj.status = "COMPLETED";
        this.searchObj.order_type = "SERVICE";
        this.getServiceList(1);
      } else if (tab == "REJECTED") {
        this.searchObj.status = "REJECTED";
        this.searchObj.customer_id = this.id;
        this.searchObj.order_type = "SERVICE";
        this.getServiceList(1);
      }
    },
    pageChange(page) {
      this.getProductList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getProductList(1);
    },
    pageServiceChange(page) {
      this.getServiceList(page);
    },
    itemsServicePerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getServiceList(1);
    },

    getServiceList(page, sortBy, orderBy) {
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.searchObj.sort_order = orderBy;
      }
      if (!page) {
        page = 1;
      }
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      // this.searchObj.row_per_page = this.itemsPerPage;

      this.serviceOrder = [];
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: "orders",
          _body: this.searchObj,
        })
        .then((res) => {
          this.serviceOrder = res.list;
          for (var k in res.list) {
            res.list[k].created_at = moment(res.list[k].created_at).format(
              "DD/MM/YYYY HH:mm a"
            );
            res.list[k].preferred_date = moment(res.list[k].preferred_date).format(
              "DD/MM/YYYY"
            );
            //  console.log("datesss",res.list[k].created_at);
          }

          this.searchObj.offset = res.next_offset;

          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
            if (res.total_count != null && res.total_count > 0) {
              this.$refs.listPagination.setTotalCount(res.total_count);
            } else {
              this.$refs.listPagination.setTotalCount(0);
            }
          }

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, this.$t("label.Service Not Available"));
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    getProductList(page, sortBy, orderBy) {
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.searchObj.sort_order = orderBy;
      }
      if (!page) {
        page = 1;
      }
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      // this.searchObj.row_per_page = this.itemsPerPage;

      this.productOrder = [];
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: "orders",
          _body: this.searchObj,
        })
        .then((res) => {
          this.productOrder = res.list;
          for (var k in res.list) {
            res.list[k].created_at = moment(res.list[k].created_at).format(
              "DD/MM/YYYY HH:mm a"
            );
            res.list[k].preferred_date = moment(res.list[k].preferred_date).format(
              "DD/MM/YYYY"
            );
            //  console.log("datesss",res.list[k].created_at);
          }

          this.searchObj.offset = res.next_offset;

          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
            if (res.total_count != null && res.total_count > 0) {
              this.$refs.listPagination.setTotalCount(res.total_count);
            } else {
              this.$refs.listPagination.setTotalCount(0);
            }
          }

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, this.$t("label.Product Not Available"));
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        this.$t("label.Delete"),
        this.$t("label.Are you sure you want to delete"),
        id
      );
    },
    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "product/category/" + id,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.deleteModal.closeModal();
          this.getProductCategoryList(0);
          this.getServiceCategoryList(0);
        })
        .catch(() => {});
    },
    update() {
      if (this.id) {
        this.$api
          .putAPI({
            _action: "customer/" + this.id,
            _body: this.detail,
            _buttonId: "submit-button",
          })
          .then((res) => {
            this.uploadImage(this.id);
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {});
      }
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert(this.$t("label.Invalid file formate, please use jpeg or png file."));
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert(this.$t("label.File must be less then 10 MB"));
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    uploadImage() {
      if (!this.file) {
        this.$router.go(-1);
        return;
      }
      this.$api
        .uploadImageAPI({
          _action: "customer/" + this.id + "/image",
          _key: "image",
          _file: this.file,
        })
        .then((res) => {
          this.detail.avatar = res.path;
          this.$router.push("/manage-customer");
        });
    },
    getCustomerDetail() {
      this.$api
        .getAPI({
          _action: "customer/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;
          this.detail.address = res.info.address;
          // this.$refs.address = this.detail.address;
          // console.log(this.detail.address);
          this.id = this.detail.id;
          $("#address1").val(this.detail.address);
        })
        .catch(() => {});
    },
  },
};
</script>

