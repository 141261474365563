<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row p-3 align-items-center">
                <div class="col-md-6 col-5">
                  <h2 class="page-title mb-md-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ $t("label.Reports") }}
                  </h2>
                </div>
              </div>
              <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
                <table class="table table-style-1 table-responsive" id="sorting_tbl">
                  <thead>
                    <tr class="row col 12">
                      <th class="col-4">{{ $t("label.S_No") }}</th>
                      <th class="col-4">{{ $t("label.Type") }}</th>
                      <th class="col-4">{{ $t("label.Action") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="row col 12">
                      <td class="col-4">1</td>
                      <td class="col-4">{{ $t("label.Customer Report") }}</td>
                      <td class="col-4">
                        <button
                          class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                          v-on:click="$router.push('/report-view/' + 'customers')"
                        >
                          <i class="bx bx-show"></i>
                        </button>
                      </td>
                    </tr>
                    <tr class="row col 12">
                      <td class="col-4">2</td>
                      <td class="col-4">{{ $t("label.Supplier Report") }}</td>
                      <td class="col-4">
                        <button
                          class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                          v-on:click="$router.push('/report-view/' + 'suppliers')"
                        >
                          <i class="bx bx-show"></i>
                        </button>
                      </td>
                    </tr>
                    <tr class="row col 12">
                      <td class="col-4">3</td>
                      <td class="col-4">{{ $t("label.Products Sales Report") }}</td>
                      <td class="col-4">
                        <button
                          class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                          v-on:click="$router.push('/report-view/' + 'customers')"
                        >
                          <i class="bx bx-show"></i>
                        </button>
                      </td>
                    </tr>
                    <tr class="row col 12">
                      <td class="col-4">4</td>
                      <td class="col-4">{{ $t("label.Service Sales Report") }}</td>
                      <td class="col-4">
                        <button
                          class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                          v-on:click="$router.push('/report-view/' + 'customers')"
                        >
                          <i class="bx bx-show"></i>
                        </button>
                      </td>
                    </tr>
                    <tr class="row col 12">
                      <td class="col-4">5</td>
                      <td class="col-4">{{ $t("label.Total Sales Report") }}</td>
                      <td class="col-4">
                        <button
                          class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                          v-on:click="$router.push('/report-view/' + 'customers')"
                        >
                          <i class="bx bx-show"></i>
                        </button>
                      </td>
                    </tr>
                    <tr class="row col 12">
                      <td class="col-4">6</td>
                      <td class="col-4">{{ $t("label.Supplier Credit Balance Report") }}</td>
                      <td class="col-4">
                        <button
                          class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                          v-on:click="$router.push('/report-view/' + 'customers')"
                        >
                          <i class="bx bx-show"></i>
                        </button>
                      </td>
                    </tr>
                    <tr class="row col 12">
                      <td class="col-4">7</td>
                      <td class="col-4">{{ $t("label.Withdrawal Request Report") }}</td>
                      <td class="col-4">
                        <button
                          class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                          v-on:click="$router.push('/report-view/' + 'customers')"
                        >
                          <i class="bx bx-show"></i>
                        </button>
                      </td>
                    </tr>
                    <tr class="row col 12">
                      <td class="col-4">8</td>
                      <td class="col-4">{{ $t("label.Product Orders Report") }}</td>
                      <td class="col-4">
                        <button
                          class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                          v-on:click="$router.push('/report-view/' + 'product_orders')"
                        >
                          <i class="bx bx-show"></i>
                        </button>
                      </td>
                    </tr>
                    <tr class="row col 12">
                      <td class="col-4">9</td>
                      <td class="col-4">{{ $t("label.Service Orders Report") }}</td>
                      <td class="col-4">
                        <button
                          class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                          v-on:click="$router.push('/report-view/' + 'customers')"
                        >
                          <i class="bx bx-show"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "ReportList",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

