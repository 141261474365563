<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row p-3 align-items-center">
                <div class="col-md-6 col-5">
                  <h2 class="page-title mb-md-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    Operating Area/Region
                  </h2>
                </div>
              </div>
              <div class="card card-body form-style content-card">
                <div class="row mb-md-4 mb-3">
                  <div class="col-md-12 form-group">
                    <label> Operating Region/Area</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="bx bxs-map"></i></span>
                      </div>
                      <vue-google-autocomplete
                        ref="address"
                        id="map"
                        :classname="detail.area ? 'form-control filled' : 'form-control'"
                        placeholder=""
                        v-model="detail.area"
                        v-on:placechanged="getAddressData"
                        types=""
                      >
                      </vue-google-autocomplete>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <iframe
                        id="iFrame"
                        width="100%"
                        height="200"
                        style="border: 0"
                        allowfullscreen=""
                        loading="lazy"
                      ></iframe>
                      <gmap-map
                        :center="center"
                        :zoom="10"
                        class="col-lg-7"
                        style="height: 555px"
                        v-if="locations.length > 0"
                      >
                        <gmap-marker
                          :key="index"
                          v-for="(gmp, index) in locations"
                          :position="gmp"
                          @click="center = gmp"
                        ></gmap-marker>
                      </gmap-map>
                    </div>
                  </div>

                  <div class="col-12 text-center mt-4">
                    <button type="button" class="btn btn-brand-1">Save</button>
                    <button
                      type="button"
                      class="btn btn-brand-1 cancel"
                      v-no:click="$router.go(-1)"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  name: "OperatingArea",
  components: {
    VueGoogleAutocomplete,
  },
  data() {
    return {
      detail: {
        latitude: "",
        longitude: "",
      },
      center: {},
      // markers: [
      //   {
      //     position: {
      //       lat: 51.093048,
      //       lng: 6.84212,
      //     },
      //   },
      // ],
      distance: "",
      iframe: "",
      locations: [],
    };
  },
  computed: {
    getArea() {
      return this.$store.getters.getArea;
    },
  },
  mounted() {
    this.$refs.address.focus();
    var $this = this;
    setTimeout(() => {
      // document.getElementById("title").value = $this.meetingData.title;
      document.getElementById("map").value = $this.detail.area;
    }, 500);
    this.detail = $this.getArea;
    //   consol e.log("object",this.detail);
  },
  methods: {
    getAddressData: function (addressData, placeResultData) {
      this.detail.address = addressData.locality;
      this.detail.latitude = addressData.latitude;
      this.detail.longitude = addressData.longitude;
      this.iframe = `https://www.google.com/maps/embed/v1/place?key=AIzaSyASCY2B1hkECL9Hax0W5A71GDQv2BdwgdE&q=${placeResultData.formatted_address}&center=${this.detail.latitude},${this.detail.longitude}&zoom=18`;
      this.updateIFrame();
    },
    updateIFrame() {
      var frame = document.getElementById("iFrame");
      if (frame) {
        frame.contentWindow.location.replace(this.iframe);
      }
    },
    showMap() {
      if (this.detail.latitude && this.detail.longitude) {
        this.distance = this.$api.getDistance(
          this.detail.latitude,
          this.detail.longitude
        );
        var obj = {
          lat: this.detail.latitude,
          lng: this.detail.longitude,
          label: this.detail.area,
        };

        if (!this.center.lat) {
          this.center = obj;
        }
      }
      this.locations.push(obj);
    },
    showLocation(detail) {
      var url =
        "https://www.google.com/maps?saddr=" +
        this.$api.getLatitude() +
        "," +
        this.$api.getLongitude() +
        "&daddr=" +
        detail.latitude +
        "," +
        detail.longitude;
      window.open(url);
    },
  },
};
</script>

