<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row mt-3 p-3 align-items-center">
                <div class="col-md-6 text-md-left text-center">
                  <h2 class="page-title mb-md-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i></a
                    >{{ id ? $t("label.Edit") : $t("label.Add") }} {{ $t("label.Service") }}
                  </h2>
                </div>
                <div class="col-md-6 text-right d-md-flex justify-content-end mb-3"></div>
              </div>
              <div class="card card-body form-style content-card">
                <div class="row justify-content-center">
                  <div class="col-lg-4 col-md-6 text-center" v-if="!this.id">
                    <label for="">{{ $t("label.Service Image") }}</label>
                    <div class="custom-select-img">
                      <div class="file-upload">
                        <div class="image-upload-wrap p-3 mb-4">
                          <input
                            type="file"
                            name="img"
                            id="pickFile"
                            class="file-upload-input"
                            v-on:change="selectedFile($event)"
                            accept="image/*"
                          />
                          <div class="drag-text text-center">
                            <img
                              id="preview"
                              :src="
                                detail?.image && detail?.image != null
                                  ? detail?.image
                                  : '/images/Imageplace-holder.svg'
                              "
                              width="40"
                            />
                            <h3 class="heading font-20">
                              {{ $t("label.Upload a image") }}
                            </h3>
                            <p class="font-12 text-secondary mb-1" for="pickFile">
                              PNG, GIF, JPEG. {{ $t("label.(Max 10 mb)") }}
                            </p>
                            <p class="font-12 text-secondary mb-0">
                              {{ $t("label.(Optional)") }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 text-center" v-if="this.id">
                    <label for=""> {{ $t("label.Service Image") }}</label>
                    <div class="custom-select-img">
                      <div class="file-upload">
                        <div class="image-upload-wrap p-3 mb-4">
                          <input
                            type="file"
                            name="img"
                            id="pickFile"
                            class="file-upload-input"
                            v-on:change="onChange($event)"
                            accept="image/*"
                          />
                          <div class="drag-text text-center">
                            <img
                              id="preview"
                              :src="
                                detail.image
                                  ? detail.image
                                  : '/images/Imageplace-holder.svg'
                              "
                              width="40"
                            />
                            <h3 class="heading font-20">
                              {{ $t("label.Upload a image") }}
                            </h3>
                            <p class="font-12 text-secondary mb-1" for="pickFile">
                              PNG, GIF, JPEG. {{ $t("label.(Max 10 mb)") }}
                            </p>
                            <p class="font-12 text-secondary mb-0">
                              {{ $t("label.(Optional)") }}
                            </p>
                          </div>
                        </div>
                        <div class="file-upload-content">
                          <img class="file-upload-image mb-3" src="#" alt="your image" />
                          <div class="image-title-wrap">
                            <button
                              type="button"
                              onclick="removeUpload()"
                              class="remove-image"
                            >
                              <i class="bx bx-x mr-2" aria-hidden="true"></i>
                              <span class="image-title">{{
                                $t("label.change Image")
                              }}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-md-4 mb-3">
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <label class="">{{ $t("label.Service Name") }}</label>
                        <span class="text-danger">*</span>
                        <Field
                          type="text"
                          id="fname"
                          name="fname"
                          v-model="detail.title"
                          class="form-control filled"
                          rules="required:name"
                          :validateOnInput="true"
                        />
                      </div>
                      <ErrorMessage name="fname" class="validation-msg" />
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label class="text-secondary-2 font-14 mb-0">{{
                          $t("label.Description")
                        }}</label>
                        <span class="text-danger">*</span>
                        <textarea
                          id="description"
                          name="description"
                          class="form-control filled"
                          style="height: 70px"
                          v-model="detail.description"
                          cols="30"
                          rows="4"
                          rules="required:description"
                          :validateOnInput="true"
                        ></textarea>
                      </div>
                      <ErrorMessage name="description" class="validation-msg" />
                    </div>
                    <div class="col-md-4">
                      <label>{{ $t("label.Category") }}</label>
                      <span class="text-danger">*</span>
                      <!-- <div class="selectParent mb-md-0 mr-md-3"> -->
                      <Field
                        as="select"
                        class="selectParent mb-md-0 mr-md-3"
                        v-model="detail.category_id"
                        name="category"
                        rules="required:category,true"
                      >
                        <option value="">{{ $t("label.Select") }}</option>
                        <option
                          v-for="(data, index) in categoryList"
                          :key="index"
                          :value="data.id"
                        >
                          {{ data.title }}
                        </option>
                      </Field>
                      <!-- </div> -->
                      <ErrorMessage name="category" class="validation-msg mt-2" />
                    </div>

                    <div class="col-md-12 pt-5">
                      <div class="form-group">
                        <div class="custom-control custom-switch d-inline">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="shwitch_1"
                            true-value="Y"
                            false-value="N"
                            v-model="detail.has_manual_calculation"
                          />
                          <label
                            class="custom-control-label pl-4 font-medium"
                            for="shwitch_1"
                          >
                            {{ $t("label.Allow Self Calculation") }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-lg-12 col-md-12"
                      v-if="detail.has_manual_calculation == 'Y' && showForm == '1'"
                    >
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <select
                                  name=""
                                  class="form-control"
                                  v-model="options.type"
                                  id=""
                                >
                                  <option value="TREE_TRIMMING">
                                    {{ $t("label.TREE TRIMMING") }}
                                  </option>
                                  <option value="GRASS_TRIMMING">
                                    {{ $t("label.GRASS TRIMMING") }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div
                              class="col-lg-6 col-md-6"
                              v-if="options.type == 'TREE_TRIMMING'"
                            >
                              <div class="form-group">
                                <select
                                  name=""
                                  class="form-control"
                                  v-model="options.tree_type"
                                  id=""
                                >
                                  <option value="SHORT">{{ $t("label.SHORT") }}</option>
                                  <option value="MEDIUM">{{ $t("label.MEDIUM") }}</option>
                                  <option value="SHORT_MEDIUM">
                                    {{ $t("label.SHORT MEDIUM") }}
                                  </option>
                                  <option value="LONG">{{ $t("label.LONG") }}</option>
                                  <option value="NONE">{{ $t("label.NONE") }}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label class="">{{ $t("label.Title") }}</label>
                                <span class="text-danger">*</span>
                                <Field
                                  type="text"
                                  id="title"
                                  name="title"
                                  v-model="options.title"
                                  class="form-control filled"
                                  :validateOnInput="true"
                                />
                              </div>
                              <ErrorMessage name="title" class="validation-msg" />
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label class="">{{ $t("label.ordering") }}</label>
                                <span class="text-danger">*</span>
                                <Field
                                  type="number"
                                  id="ordering_area"
                                  name="ordered"
                                  v-model="options.ordered"
                                  class="form-control filled"
                                  min="0"
                                  :validateOnInput="true"
                                />
                              </div>
                              <ErrorMessage name="ordered" class="validation-msg" />
                            </div>
                          </div>
                          <div class="row" v-if="options.type == 'GRASS_TRIMMING'">
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label class="">{{ $t("label.Range From") }}</label>
                                <span class="text-danger">*</span>
                                <Field
                                  type="number"
                                  id="range_from"
                                  name="range_from"
                                  v-model="options.range_from"
                                  class="form-control filled"
                                  rules="required:range_from"
                                  min="0"
                                  :validateOnInput="true"
                                />
                              </div>
                              <ErrorMessage name="range_from" class="validation-msg" />
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label class="">{{ $t("label.Range To") }}</label>
                                <span class="text-danger">*</span>
                                <Field
                                  type="number"
                                  id="range_to"
                                  name="range_to"
                                  v-model="options.range_to"
                                  class="form-control filled"
                                  rules="required:range_to"
                                  min="0"
                                  :validateOnInput="true"
                                />
                              </div>
                              <ErrorMessage name="range_to" class="validation-msg" />
                            </div>
                          </div>

                          <div class="row mx-auto">
                            <div class="col-lg-12 d-flex justify-content-end">
                              <button
                                type="button"
                                class="btn btn-brand-1"
                                v-on:click="addSelfCalculation()"
                              >
                                {{ $t("label.Add") }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-lg-12 d-flex justify-content-end"
                      v-if="showForm == '0'"
                    >
                      <button
                        type="button"
                        class="btn btn-brand-1"
                        v-on:click="toShowFrom()"
                      >
                        {{ $t("label.Add More") }}
                      </button>
                    </div>
                    <div class="col-lg-12 col-md-12 pt-5" v-if="optionsList.length > 0">
                      <div class="card">
                        <div class="card-body">
                          <table
                            class="table table-style-1 table-responsive"
                            id="sorting_tbl"
                          >
                            <thead>
                              <tr>
                                <th>{{ $t("label.S_No") }}</th>
                                <th>{{ $t("label.Title") }}</th>
                                <th>{{ $t("label.ordering") }}</th>
                                <th>{{ $t("label.Tree Type") }}</th>
                                <th>{{ $t("label.Range From") }}</th>
                                <th>{{ $t("label.Range To") }}</th>
                                <th>{{ $t("label.Action") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(data, index) in optionsList" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td>{{ data?.title }}</td>
                                <td>{{ data?.ordered }}</td>
                                <td>
                                  {{ data?.tree_type ? data?.tree_type : "-" }}
                                </td>
                                <td>{{ data?.range_from ? data?.range_from : "-" }}</td>
                                <td>{{ data?.range_to ? data?.range_to : "-" }}</td>
                                <td>
                                  <div class="d-flex flex-row">
                                    <button
                                      class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                                      v-on:click="editOptions(data, index)"
                                      title="Edit"
                                    >
                                      <i class="bx bxs-pencil"></i>
                                    </button>
                                    <button
                                      class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                                      v-on:click="deleteOptions(index, data)"
                                      title="Delete"
                                    >
                                      <i class="bx bxs-trash"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="text-center col-12 pt-5">
                    <button
                      id="save-button"
                      class="btn btn-brand-1 mx-4"
                      v-on:click="Save()"
                    >
                      {{ $t("label.Save") }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-brand-1 cancel"
                      v-on:click="$router.go(-1)"
                    >
                      {{ $t("label.Cancel") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <TextModal ref="textModal">
    <div class="spx-modal h-100 justify-content-center">
      <div class="spx-modal-body mx-auto">
        <div class="modal-header pt-0">
          <button type="button" class="close" v-on:click="$refs.textModal.closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">{{ $t("label.Edit Self Calculation") }}</h5>
        </div>
        <div class="modal-body">
          <div class="col-lg-12 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <select name="" class="form-control" v-model="options.type" id="">
                        <option value="TREE_TRIMMING">
                          {{ $t("label.TREE TRIMMING") }}
                        </option>
                        <option value="GRASS_TRIMMING">
                          {{ $t("label.GRASS TRIMMING") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6" v-if="options.type == 'TREE_TRIMMING'">
                    <div class="form-group">
                      <select
                        name=""
                        class="form-control"
                        v-model="options.tree_type"
                        id=""
                      >
                        <option value="SHORT">{{ $t("label.SHORT") }}</option>
                        <option value="MEDIUM">{{ $t("label.MEDIUM") }}</option>
                        <option value="SHORT_MEDIUM">
                          {{ $t("label.SHORT MEDIUM") }}
                        </option>
                        <option value="LONG">{{ $t("label.LONG") }}</option>
                        <option value="NONE">{{ $t("label.NONE") }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label class="">{{ $t("label.Title") }}</label>
                      <span class="text-danger">*</span>
                      <Field
                        type="text"
                        id="title"
                        name="title"
                        v-model="options.title"
                        class="form-control filled"
                        :validateOnInput="true"
                      />
                    </div>
                    <ErrorMessage name="title" class="validation-msg" />
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label class="">{{ $t("label.ordering") }}</label>
                      <span class="text-danger">*</span>
                      <Field
                        type="number"
                        id="ordering_area"
                        name="ordered"
                        v-model="options.ordered"
                        class="form-control filled"
                        min="0"
                        :validateOnInput="true"
                      />
                    </div>
                    <ErrorMessage name="ordered" class="validation-msg" />
                  </div>
                </div>
                <div class="row" v-if="options.type == 'GRASS_TRIMMING'">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label class="">{{ $t("label.Range From") }}</label>
                      <span class="text-danger">*</span>
                      <Field
                        type="number"
                        id="range_from"
                        name="range_from"
                        v-model="options.range_from"
                        class="form-control filled"
                        rules="required:range_from"
                        min="0"
                        :validateOnInput="true"
                      />
                    </div>
                    <ErrorMessage name="range_from" class="validation-msg" />
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label class="">{{ $t("label.Range To") }}</label>
                      <span class="text-danger">*</span>
                      <Field
                        type="number"
                        id="range_to"
                        name="range_to"
                        v-model="options.range_to"
                        class="form-control filled"
                        rules="required:range_to"
                        min="0"
                        :validateOnInput="true"
                      />
                    </div>
                    <ErrorMessage name="range_to" class="validation-msg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer pb-0">
          <button
            class="fill-btn btn-success"
            id="save-button"
            style="width: 18%; border-radius: 5px"
            v-on:click="updateSelfCalculation()"
          >
          {{ $t("label.Save") }}
          </button>
          <button
            type="button"
            class="fil-btn btn-danger"
            id="cancel-button"
            style="width: 18%; border-radius: 5px"
            v-on:click="$refs.textModal.closeModal()"
          >
          {{ $t("label.Cancel") }}
          </button>
        </div>
      </div>
    </div>
  </TextModal>
  <DeleteModal @attributeDelete="deleteRecord" ref="deleteModal" />
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import TextModal from "../../components/TextModal.vue";
import DeleteModal from "@/components/DeleteModal";
import $ from "jquery";
export default {
  name: "AddtService",
  components: {
    DeleteModal,
    Field,
    ErrorMessage,
    TextModal,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {
        status: "APPROVED",
        options: [],
        has_manual_calculation: "N",
      },
      options: {
        type: "TREE_TRIMMING",
      },
      file: undefined,
      imageObj: {},
      categoryList: [],
      optionsList: [],
      showForm: "1",
      index: "",
      service_id: "",
    };
  },
  mounted() {
    this.getCategory();
    if (this.id) {
      this.getDetail();
      this.showForm = "0";
    }
  },
  methods: {
    deleteRecord(obj) {
      console.log(obj);
      if (!obj.id) {
        this.optionsList.splice(obj.index, 1);
      } else {
        this.$api
          .deleteAPI({
            _action: "service/" + obj.service_id + "/attr/" + obj.id,
          })
          .then((res) => {
            this.optionsList.splice(obj.index, 1);
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.deleteModal.closeModal();
          })
          .catch(() => {
            this.$refs.deleteModal.closeModal();
          });
      }

     
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "service/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;
          this.optionsList = res.info.options;
          this.getSubCategory(res.category.id);
        })
        .catch(() => {});
    },
    addSelfCalculation() {
      var title = $("#title").val();
      var ordering_area = $("#ordering_area").val();
      if (title == "") {
        this.$notify({
          type: "error",
          text: "Please enter title",
        });
        return;
      }
      if (ordering_area == "") {
        this.$notify({
          type: "error",
          text: "Please enter ordering",
        });
        return;
      }

      this.optionsList.push({
        id:null,
        title: this.options.title,
        ordered: this.options.ordered,
        type: this.options.type,
        tree_type: this.options.tree_type,
        range_from: this.options.range_from,
        range_to: this.options.range_to,
      });

      if (this.optionsList.length > 0) {
        this.options = { type: "TREE_TRIMMING", title: "", ordered: "" };
      }

      this.showForm = "0";

      console.log("list Of service list data", this.optionsList);
    },
    updateSelfCalculation() {
      this.$refs.textModal.closeModal();
      // this.$set(this.optionsList, this.index, this.optionsList[this.index]);
      



      if(this.options.type == "TREE_TRIMMING"){
        this.options.range_from = null;
        this.options.range_to = null;
      }
      this.optionsList[this.index] = this.options;
      console.log(this.optionsList,this.options, this.index);
    },
    deleteOptions(index, data) {
      var obj = data;
      obj.index = index;

      this.$refs.deleteModal.specificsModel(
        this.$t("label.Delete"),
        this.$t("label.Are you sure you want to delete"),
        obj
      );
    },
    toShowFrom() {
      this.showForm = "1";
      this.options = {type : "TREE_TRIMMING"};
    },
    editOptions(data, index) {
      this.index = index;
      var temp = JSON.stringify(data);
      this.options = JSON.parse(temp);
      this.$refs.textModal.showModal();
    },
    getCategory() {
      this.$api
        .getAPI({
          _action: "service/categories",
        })
        .then((res) => {
          this.categoryList = res.list;
        })
        .catch(() => {});
    },
    Save() {
      for (var i in this.attributeList) {
        if (this.attributeList[i].value) {
          var obj = {
            code: this.attributeList[i].code,
            value: this.attributeList[i].value,
          };

          this.detail.attributes.push(obj);
        }
      }
      this.detail.options = this.optionsList;

      console.log("this is optionList data ", this.optionsList);
      if (this.id) {
        this.$api
          .putAPI({
            _action: "service/" + this.id,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.service_id = res.id;
            this.$router.push("/manage-product-service");
            this.uploadImage();
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "service",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.service_id = res.id;
            this.$router.push("/manage-product-service");
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.uploadImage();
          })
          .catch(() => {});
      }
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert(this.$t("label.Invalid file formate, please use jpeg or png file."));
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert(this.$t("label.File must be less then 10 MB"));
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    onChange(e) {
      let files = e.target.files;
      this.file = files[0];
      if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $("#preview").attr("src", e.target.result);
        };
      }
      reader.readAsDataURL(e.target.files[0]);
    },
    uploadImage() {
      if (!this.file) {
        this.$router.go(-1);
      } else {
        this.$api
          .uploadImageAPI({
            _action: "service/image/" + this.service_id,
            _key: "image",
            _file: this.file,
            _body: { image: this.detail.image },
          })
          .then(() => {
            // this.$notify({
            //   type: "success",
            //   text: res.message,
            // });
          });
      }
    },
  },
};
</script>

