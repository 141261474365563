<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row p-3 align-items-center">
                <div class="col-md-6 col-5">
                  <h2 class="page-title mb-md-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ $t("label.Manage Sub Categories") }}
                  </h2>
                </div>
              </div>

              <!-- <ul
                class="nav nav-pills mb-3 tab-style-1"
                id="pills-tab"
                role="tablist"
              >
                <li class="col-6 text-center nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pending_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'PRODUCT' }"
                    v-on:click="changeTab('PRODUCT')"
                    style="cursor: pointer"
                    >Products</a
                  >
                </li>
                <li class="col-6 text-center nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="accepted_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'SERVICE' }"
                    v-on:click="changeTab('SERVICE')"
                    style="cursor: pointer"
                    >Services</a
                  >
                </li>
              </ul> -->
              <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pending_orders"
                  role="tabpanel"
                  aria-labelledby="pending_orders-tab"
                >
                  <div class="row mb-3">
                    <div class="col-lg-6 col-md-4 d-md-flex mb-md-0 mb-2">
                      <div class="input-group page-cont-search border col-md-6 p-0">
                        <span class="input-group-text"><i class="bx bx-search"></i></span>
                        <input
                          type="text"
                          class="form-control"
                          style="height: 55px"
                          name=""
                          v-on:keyup.enter="getSubCategoryList(1)"
                          :placeholder="$t('label.search here')"
                          v-model="searchObj.keyword"
                        />
                      </div>
                    </div>
                    <div
                      class="col-md-6 col-7 text-right d-md-flex justify-content-end mb-3"
                    >
                      <button
                        type="button"
                        class="btn btn-brand-1 btn-sm"
                        v-on:click="
                          $router.push(
                            '/add-sub_category?type=' + this.type + '&parentId=' + this.id
                          )
                        "
                      >
                        <i class="bx bx-plus"></i> {{ $t("label.Add") }}
                      </button>
                    </div>
                  </div>
                  <table class="table table-style-1 table-responsive" id="sorting_tbl">
                    <thead>
                      <tr>
                        <th>{{ $t("label.S_No") }}</th>
                        <th>{{ $t("label.Sub Category Name") }}</th>
                        <th>{{ $t("label.Action") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in subCategory" :key="index">
                        <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                        <td>{{ data.title }}</td>
                        <td>
                          <!-- <button class="btn btn-brand-1 px-5 mr-2">
                            View Sub-Category-->
                          <!-- <i class="bx bx-show"></i> -->
                          <!--</button> -->
                          <button
                            class="btn btn-action mr-2 mb-md-0 mb-2 text-success"
                            v-on:click="
                              $router.push(
                                '/edit-sub_category/' +
                                  data.id +
                                  '?type=' +
                                  this.type +
                                  '&?parent_category_id=' +
                                  data?.parent_category.id
                              )
                            "
                          >
                            <i class="bx bxs-pencil"></i>
                          </button>
                          <button
                            class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                            v-on:click="showDeletePopup(data.id)"
                          >
                            <i class="bx bxs-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ErrorComponent @retry="getSubCategoryList(1)" ref="errorComponent" />
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-lg-12 mt-3">
                  <div class="page-bottom-pagination text-right">
                    <Pagination
                      @page-change="pageChange"
                      @items-per-page-change="itemsPerPageChange"
                      ref="listPagination"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
</template>
<script>
// import moment from "moment";
import DeleteModal from "@/components/DeleteModal";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "ManageCategories",

  components: {
    ErrorComponent,
    Pagination,
    DeleteModal,
  },

  data() {
    return {
      id: this.$route.params.id,
      type: this.$route.query.type,
      subCategory: [],
      url: "",
      searchObj: {
        keyword: "",
        offset: 0,
      },
      currentPage: 1,
      loader: true,
      itemsPerPage: 10,
    };
  },

  mounted() {
    if (this.type == "PRODUCT") {
      this.url = "product/categories";
      this.getSubCategoryList(1);
    } else if (this.type == "SERVICE") {
      this.url = "service/categories";
      this.getSubCategoryList(1);
    }

    console.log(this.$refs.deleteModal);
  },

  methods: {
    pageChange(page) {
      this.getSubCategoryList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getSubCategoryList(1);
    },
    getSubCategoryList(page, sortBy, orderBy) {
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.searchObj.sort_order = orderBy;
      }
      if (!page) {
        page = 1;
      }
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      // this.searchObj.row_per_page = this.itemsPerPage;

      this.subCategory = [];
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: this.url + "?parent_category_id=" + this.id,
          _body: this.searchObj,
        })
        .then((res) => {
          this.subCategory = res.list;
          this.searchObj.offset = res.next_offset;

          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
            if (res.count != null && res.count > 0) {
              this.$refs.listPagination.setTotalCount(res.count);
            } else {
              this.$refs.listPagination.setTotalCount(0);
            }
          }

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, this.$t("label.Sub Category Not Available"));
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        this.$t("label.Delete"),
        this.$t("label.Are you sure you want to delete"),
        id
      );
    },
    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "product/category/" + id,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.deleteModal.closeModal();
          this.getSubCategoryList(1);
        })
        .catch(() => {});
    },
  }
};
</script>

