<template>
    <main role="main">
        <div class="d-lg-flex" id="main-wraper">
            <div class="page-wrapper" style="width: 140%">
                <div class="page-content">
                    <div class="row mb-4 justify-content-center">
                        <div class="col-lg-12">
                            <div class="row p-3 align-items-center">
                                <div class="col-md-6 col-5">
                                    <h2 class="page-title mb-md-0">
                                        <a href="javascript:void(0)" v-on:click="$router.go(-1)" class="back-arrow">
                                            <i class="bx bx-arrow-back"></i></a>
                                        Manage Withdraw Requests
                                    </h2>
                                </div>
                            </div>

                            <ul class="col-12 nav nav-pills mb-3 tab-style-1" id="pills-tab" role="tablist">
                                <li class="col-3 nav-item text-center" role="presentation">
                                    <a class="nav-link" id="pending_orders-tab" data-toggle="pill" role="tab"
                                        :class="{ active: currentTab == 'PENDING' }" v-on:click="changeTab('PENDING')"
                                        style="cursor: pointer">Pending</a>
                                </li>
                                <li class="col-3 nav-item text-center" role="presentation">
                                    <a class="nav-link" id="accepted_orders-tab" data-toggle="pill" role="tab"
                                        :class="{ active: currentTab == 'ACCEPTED' }" v-on:click="changeTab('ACCEPTED')"
                                        style="cursor: pointer">Accepted</a>
                                </li>
                                <li class="col-3 nav-item text-center" role="presentation">
                                    <a class="nav-link" id="rejected_orders-tab" data-toggle="pill" role="tab"
                                        :class="{ active: currentTab == 'REJECTED' }" v-on:click="changeTab('REJECTED')"
                                        style="cursor: pointer">Rejected</a>
                                </li>
                                <li class="col-3 nav-item text-center" role="presentation">
                                    <a class="nav-link" id="cancelled_orders-tab" data-toggle="pill" role="tab"
                                        :class="{ active: currentTab == 'CANCELLED' }" v-on:click="changeTab('CANCELLED')"
                                        style="cursor: pointer">Cancelled</a>
                                </li>
                            </ul>
                            <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pending_orders" role="tabpanel"
                                    aria-labelledby="pending_orders-tab">
                                    <div class="row mb-3">
                                        <div class="col-lg-6 col-md-4 d-md-flex mb-md-0 mb-2">
                                            <div class="input-group page-cont-search border col-md-6">
                                                <span class="input-group-text"><i class="bx bx-search"></i></span>
                                                <input type="text" class="form-control" name=""
                                                    v-on:keyup.enter="getSupplierList(1)" :placeholder="$t('label.search here')"
                                                    v-model="searchObj.keyword" />
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-7 text-right d-md-flex justify-content-end mb-3">
                                        </div>
                                    </div>
                                    <table class="table table-style-1 table-responsive" id="sorting_tbl">
                                        <thead>
                                            <tr>
                                                <th scope="col">Supplier Name</th>
                                                <th scope="col">All Earnings</th>
                                                <th scope="col" class="serive-name">Total Order Payment</th>
                                                <th scope="col">Your Earnings</th>
                                                <th v-if="currentTab == 'PENDING'">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data, index) in supplierList" :key="index">
                                                <td>
                                                    <span v-if="data.supplier">{{ data.supplier.name }}</span>
                                                    <span v-else></span>
                                                </td>
                                                <td> <span class="font-medium">Order No. #{{ data.withdrawal_req_no
                                                }}</span><br />
                                                    <span>{{ data.created_at }}</span>
                                                </td>
                                                <td>SAR {{ data.total_amount }}</td>
                                                <td>SAR {{ data.earnings }}</td>
                                                <td v-if="currentTab == 'PENDING'">
                                                    <button v-show="currentTab == 'PENDING'"
                                                        class="btn btn-action mr-2 mb-md-0 mb-2 text-success"
                                                        v-on:click="status('ACCEPTED', data.id)" title="Accept">
                                                        <i class="bx bxs-check-circle"></i>
                                                    </button>
                                                    <button v-show="currentTab == 'PENDING'"
                                                        class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                                                        v-on:click="status('REJECTED', data.id)" title="Reject">
                                                        <i class="bx bxs-x-circle"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <ErrorComponent @retry="getSupplierList(1)" ref="errorComponent" />
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-lg-12 mt-3">
                                    <div class="page-bottom-pagination text-right">
                                        <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                                            ref="listPagination" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
  
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "../../components/Pagination.vue";
export default {
    name: "ManageEarning",
    components: {
        ErrorComponent,
        Pagination,
    },
    data() {
        return {
            currentTab: "PENDING",
            supplierList: [],
            searchObj: {
                keyword: "",
                status: "PENDING",
                offset: 0,
            },
            currentPage: 1,
            access: { can_create: 1, can_update: 1, can_read: 1, can_delete: 1 },
            loader: true,
            itemsPerPage: 10,
            reason: ""
        };
    },
    mounted() {
        this.getSupplierList(1);
    },
    methods: {
        changeTab(tab) {
            this.currentTab = tab;
            this.searchObj.status = tab;
            this.getSupplierList(0);
        },
        pageChange(page) {
            this.getSupplierList(page);
        },
        itemsPerPageChange() {
            this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
            this.getSupplierList(1);
        },
        getSupplierList(page, sortBy, orderBy) {
            if (sortBy) {
                this.searchObj.sort_by = sortBy;
            }
            if (orderBy) {
                this.searchObj.sort_order = orderBy;
            }
            if (!page) {
                page = 1;
            }
            this.searchObj.offset = page - 1;
            this.currentPage = page;

            this.supplierList = [];
            if (this.$refs.errorComponent) {
                this.$refs.errorComponent.updateLoader(true);
            }
            this.$api
                .getAPI({
                    _action: "withdraw-requests",
                    _body: this.searchObj,
                })
                .then((res) => {
                    this.supplierList = res.list;
                    this.searchObj.offset = res.next_offset;


                    if (this.$refs.listPagination) {
                        this.$refs.listPagination.currentPage = this.currentPage;
                        if (res.total_count != null && res.total_count > 0 && this.access.can_read) {
                            this.$refs.listPagination.setTotalCount(res.total_count);
                        } else {
                            this.$refs.listPagination.setTotalCount(0);
                        }
                    }

                    if (this.$refs.errorComponent) {
                        if (this.access.can_read == 0) {
                            this.$refs.errorComponent.setData(res, this.$t("label.Sorry, you don't have permission"));
                            this.$refs.errorComponent.updateLoader(false);
                            return;
                        }
                        this.$refs.errorComponent.setData(res, this.$t("label.Withdrawal Request Not Found"));
                        this.$refs.errorComponent.updateLoader(false);
                    }
                })
                .catch(() => {
                    this.loader = false;
                    if (this.$refs.errorComponent) {
                        this.$refs.errorComponent.setData(0, undefined, "");
                        this.$refs.errorComponent.updateLoader(false);
                    }
                });
        },
        router(id) {
            this.$router.push("/edit-supplier/" + id);
        },
        status(status, id) {
            var obj = {};
            obj.status = status;
            obj.reason = this.reason;
            this.$api
                .putAPI({
                    _action: "withdraw-request/" + id,
                    _body: obj,
                })
                .then((res) => {
                    this.$notify({
                        type: "success",
                        text: res.info.message,
                    });
                    this.getSupplierList(0);
                })
                .catch(() => { });
        },
        showDeletePopup(id) {
            this.$refs.deleteModal.showModal(
                this.$t("label.Delete"),
                this.$t("label.Are you sure you want to delete"),
                id
            );
        },
        deleteRecord(id) {
            this.$api
                .deleteAPI({
                    _action: "supplier/" + id,
                })
                .then((res) => {
                    this.$notify({
                        type: "success",
                        text: res.message,
                    });
                    this.$refs.deleteModal.closeModal();
                    this.getSupplierList(0);
                })
                .catch(() => { });
        },
    },
};
</script>

