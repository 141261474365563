<template>
  <div class="d-lg-flex no-right-sidebar" id="main-wraper">
    <div class="page-wrapper no-right-sidebar">
      <div class="page-content">
        <div class="row mb-4 justify-content-center">
          <div class="col-lg-12">
            <div class="row p-3 align-items-center">
              <div class="col-md-6 col-5">
                <h2 class="page-title mb-md-0">
                  <a
                    href="javascript:void(0)"
                    v-on:click="$router.go(-1)"
                    class="back-arrow"
                  >
                    <i class="bx bx-arrow-back"></i
                  ></a>
                  {{ $t("label.Send Notification") }}
                </h2>
              </div>
            </div>
            <div
              class="card card-body content-card mb-4 rounded-l border-0 shadow-sm"
              style="width: 120%"
            >
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <form class="form-style">
                    <Form class="form-style floating-form" @submit="update()">
                      <div class="row justify-content-center mb-3">
                        <div class="col-md-10">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="floating-form-group with-icon">
                                <Field
                                  type="text"
                                  id="title"
                                  name="title"
                                  v-model="detail.title"
                                  class="form-control filled"
                                  rules="required:title"
                                  :validateOnInput="true"
                                />
                                <label for="title">{{ $t("label.Title") }}</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="title" class="validation-msg" />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group with-icon">
                                <textarea
                                  type="text"
                                  id="description"
                                  name="description"
                                  v-model="detail.description"
                                  class="form-control filled"
                                  rules="required:description"
                                  :validateOnInput="true"
                                />
                                <label for="description">{{ $t("label.Text") }}</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="description" class="validation-msg" />
                            </div>

                            <div class="row col-12 form-group mt-5">
                              <div class="col-2 mb-2">
                                <label class="custom-control-label">{{ $t("label.User Type") }}</label>
                              </div>
                              <div class="col-4 custom-control custom-radio mb-2">
                                <input
                                  type="radio"
                                  :id="'id_user'"
                                  name="reason"
                                  class="custom-control-input"
                                  v-on:click="selectReason('USER')"
                                />

                                <label class="custom-control-label" :for="'id_user'">
                                  {{ $t("label.Customers") }}</label
                                >
                              </div>
                              <div class="col-4 custom-control custom-radio mb-2">
                                <input
                                  type="radio"
                                  :id="'id_supplier'"
                                  name="reason"
                                  class="custom-control-input ml-5"
                                  v-on:click="selectReason('SUPPLIER')"
                                />

                                <label class="custom-control-label" :for="'id_supplier'">
                                  {{ $t("label.Suppliers") }}</label
                                >
                              </div>
                            </div>
                            <div class="row col-12 form-group">
                              <div class="col-2 mb-2">
                                <label class="custom-control-label">{{ $t("label.Send To") }}</label>
                              </div>
                              <div class="col-4 custom-control custom-radio mb-2">
                                <input
                                  type="radio"
                                  :id="'id_all'"
                                  name="sent_to"
                                  class="custom-control-input"
                                  v-on:click="selectReason2('ALL')"
                                />

                                <label class="custom-control-label" :for="'id_all'">
                                  {{ $t("label.ALL") }}</label
                                >
                              </div>
                              <div class="col-4 custom-control custom-radio mb-2">
                                <input
                                  type="radio"
                                  :id="'id_specific'"
                                  name="sent_to"
                                  class="custom-control-input ml-5"
                                  v-on:click="selectReason2('SPECIFIC')"
                                />

                                <label class="custom-control-label" :for="'id_specific'">
                                  {{ $t("label.SPECIFIC") }}</label
                                >
                              </div>
                            </div>
                            <div class="col-lg-12" v-if="this.show == 'SPECIFIC'">
                              <div class="form-group">
                                <label for="role">{{ $t("label.Select customer") }}</label>
                                <span class="text-danger">*</span>
                                <Field
                                  as="select"
                                  class="custom-select"
                                  style="height: 50px"
                                  v-model="id"
                                  name="role"
                                  rules="required:role"
                                >
                                  <option value="">{{ $t("label.Select") }}</option>
                                  <option
                                    v-for="(data, index) in roleList"
                                    :key="index"
                                    :value="data.id"
                                    v-on:click="selectId(data.id)"
                                  >
                                    {{ data.name }}
                                  </option>
                                </Field>
                              </div>
                              <ErrorMessage name="role" class="validation-msg mt-3" />
                            </div>
                          </div>
                          <div class="text-center mt-3">
                            <button class="btn btn-brand-1 mb-3 px-5" id="save-button">
                              {{ $t("label.Send") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "SendNotification",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      id: "",
      file: undefined,
      detail: {
        type: "",
        sent_to: "",
      },
      show: "",
      type: "",
      roleName: "",
      roleId: "",

      roleList: [],
    };
  },
  mounted() {},
  methods: {
    selectReason(type) {
      this.detail.type = type;
      if (type == "USER") {
        this.type = "customers";
        this.getList();
      } else if (type == "SUPPLIER") {
        this.type = "suppliers";
        this.getList();
      }
    },
    selectReason2(type) {
      this.show = type;
      this.detail.sent_to = type;
    },
    getList() {
      var obj = {};
      obj.status = "ACCEPTED";
      this.$api
        .getAPI({
          _action: this.type,
          _body: obj,
        })
        .then((res) => {
          this.roleList = res.list;
        });
    },
    selectId(id) {
      if (this.type == "suppliers") {
        this.detail.supplier_id == id;
      } else if (this.type == "customers") {
        this.detail.customer_id == id;
      }
    },
    update() {
      this.$api
        .postAPI({
          _action: "notification",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$router.push("/notification");
        })
        .catch(() => {});
    },
  },
};
</script>

