const messages = {
    en: {
        label: {
            "Dashboard": "Dashboard",
            "Services": "Services",
            "Products": "Products",
            "Product": "Product",
            "All Products": "All Products",
            "Product Orders": "Product Orders",
            "All Services": "All Services",
            "Services Orders": "Services Orders",
            "Product Delivery Charges": "Product Delivery Charges",
            "Earnings": "Earnings",
            "Manage Service Orders": "Manage Service Orders",
            "Manage Product Orders": "Manage Product Orders",
            "Manage Products": "Manage Products",
            "Manage Services": "Manage Services",
            "Show More": "Show More",
            "My Profile": "My Profile",
            "Company Profile": "Company Profile",
            "Settings": "Settings",
            "Terms & Condition": "Terms & Condition",
            "Logout": "Logout",
            "search here": "search here...",
            "Approved": "Approved",
            "Requested": "Requested",
            "Rejected": "Rejected",
            "Status": "Status",
            "Change Password": "Change Password",
            "Current Password": "Current Password",
            "New Password": "New Password",
            "Confirm Password": "Confirm Password",
            "Save": "Save",
            "Cancel": "Cancel",
            "Company Details": "Company Details",
            "Phone": "Phone",
            "Email": "Email",
            "Company Name": "Company Name",
            "Change": "Change",
            "Change Logo": "Change Logo",
            "images": "images",
            "All Withdrawal": "All Withdrawal",
            "Add Image": "Add Image",
            "Action": "Action",
            "Upload Image": "Upload Image",
            "Upload a image": "Upload a image",
            "(Max 10 mb)": "(Max 10 mb)",
            "(Optional)": "(Optional)",
            "change Image": "change Image",
            "Close": "Close",
            "Delete": "Delete",
            "Are you sure you want to delete": "Are you sure you want to delete",
            "Invalid file formate, please use jpeg or png file.": "Invalid file formate, please use jpeg or png file.",
            "File must be less then 10 MB": "File must be less then 10 MB",
            "Withdrawal Request & Date Time": "Withdrawal Request & Date Time",
            "Payment Amount": "Payment Amount",
            "Withdrawal Status": "Withdrawal Status",
            "Order No. #": "Order No. #",
            "All Earnings": "All Earnings",
            "Total Order Payment": "Total Order Payment",
            "Your Earnings": "Your Earnings",
            "Reason": "Reason",
            "Back": "Back",
            "Cancel Request": "Cancel Request",
            "Withdrawal Request ID": "Withdrawal Request ID",
            "Date": "Date",
            "Time": "Time",
            "Process may take up to 24 hours. Please contact us if the withdrawal is not completed within this period": "Process may take up to 24 hours. Please contact us if the withdrawal is not completed within this period",
            "The money will be credited to your account within 1 minute to 3 working days": "The money will be credited to your account within 1 minute to 3 working days",
            "Withdrawal Detail": "Withdrawal Detail",
            "Notifications": "Notifications",
            "No notification available": "No notification available",
            "Withrawal Request Successful": "Withrawal Request Successful",
            "OK": "OK",
            "Request": "Request",
            "Amount to Withdrawal": "Amount to Withdrawal",
            "Select Order": "Select Order",
            "Request for Withdrawal": "Request for Withdrawal",
            "Select Banks": "Select Banks",
            "Select": "Select",
            "Accoount Number": "Account Number",
            "Confirm Account Number": "Confirm Account Number",
            "IBAN Number": "IBAN Number",
            "Mobile Number": "Mobile Number",
            "Name as given in bank": "Name as given in bank",
            "Minimum Order Free Shipping": "Minimum Order Free Shipping",
            "Profile": "Profile",
            "Operating Area": "Operating Area",
            "Change photo": "Change photo",
            "Name": "Name",
            "Work Address": "Work Address",
            "Add": "Add",
            "Add More": "Add More",
            "Are you sure you want to delete this address": "Are you sure you want to delete this address",
            "Please enter operating area": "Please enter operating area",
            "Operating Area/Region": "Operating Area/Region",
            "Add Products": "Add Products",
            "Product image": "Product image",
            "Product Name": "Product Name",
            "Description": "Description",
            "Category": "Category",
            "Sub-Category": "Sub-Category",
            "Price": "Price",
            "Details": "Details",
            "Out of stock": "Out of stock",
            "Edit Products": "Edit Products",
            "Product Description": "Product Description",
            "Gardening Catelog Products": "Gardening Catelog Products",
            "My Products": "My Products",
            "Request for New Product": "Request for New Product",
            "Image": "Image",
            "Added": "Added",
            "Save & Add": "Save & Add",
            "No product available": "No product available",
            "Gardening Catelog Services": "Gardening Catelog Services",
            "My Services": "My Services",
            "Service Name": "Service Name",
            "Visit Price": "Visit Price",
            "No service available": "No service available",
            "Edit Service": "Edit Service",
            "Service Image": "Service Image",
            "Service Description": "Service Description",
            "Allow Self Calculation": "Allow Self Calculation",
            "Price for Small/Medium Tree": "Price for Small/Medium Tree",
            "Price for Large Tree": "Price for Large Tree",
            "Order Detail": "Order Detail",
            "Order Id: #": "Order Id: #",
            "Location": "Location",
            "Date & Time": "Date & Time",
            "Completed": "Completed",
            "Cancelled": "Cancelled",
            "Accepted": "Accepted",
            "Payment Status": "Payment Status",
            "S_No": "S.No.",
            "Quantity": "Quantity",
            "Amount": "Amount",
            "Accept": "Accept",
            "Reject": "Reject",
            "Mark Delivery Complete": "Mark Delivery Complete",
            "Total": "Total",
            "Delivery Charges": "Delivery Charges",
            "VAT": "VAT",
            "Discount": "Discount",
            "Sub Total": "Sub Total",
            "Customer Name": "Customer Name",
            "Preferred Date": "Preferred Date",
            "Order ID": "Order ID",
            "Manage Products Orders": "Manage Products Orders",
            "Cancelled Orders": "Cancelled Orders",
            "Delivered Orders": "Delivered Orders",
            "Rejected Orders": "Rejected Orders",
            "Accepted Orders": "Accepted Orders",
            "Pending Orders": "Pending Orders",
            "Completed Orders": "Completed Orders",
            "Tax": "Tax",
            "Manage Services Orders": "Manage Services Orders",
            "Note": "Note",
            "Pending at Supplier": "Pending at Supplier",
            "Preferred Date & Time": "Preferred Date & Time",
            "Supplier Detail": "Supplier Detail",
            "Transaction Detail": "Transaction Detail",
            "Transaction Amount": "Transaction Amount",
            "Transaction Id": "Transaction Id",
            "Transaction Date & Time": "Transaction Date & Time",
            "Payment Mode": "Payment Mode",
            "Order Status": "Order Status",
            "Select Supplier": "Select Supplier",
            "Sr_No": "Sr No.",
            "Order Not Available": "Order Not Available",
            "New Work Complete": "New Work Complete",
            "New Work Under Progress": "New Work Under Progress",
            "Pending New Work": "Pending New Work",
            "Visit Complete": "Visit Complete",
            "Outgoing": "Outgoing",
            "New Visit": "New Visit",
            "Supplier Name": "Supplier Name",
            "Manage Admins": "Manage Admins",
            "Admins": "Admins",
            "Manage Banners": "Manage Banners",
            "Upload Banner": "Upload Banner",
            "Url": "Url",
            "Manage Helps": "Manage Helps",
            "FAQ": "FAQ",
            "Question": "Question",
            "Answer": "Answer",
            "Manage FAQ": "Manage FAQ's",
            "Manage Roles": "Manage Roles",
            "Roles": "Roles",
            "Add Password": "Add Password",
            "Access to": "Access to",
            "View": "View",
            "Edit": "Edit",
            "Manage Products Approval": "Manage Products Approval",
            "Select Category": "Select Category",
            "All Category": "All Category",
            "Select Sub-Category": "Select Sub-Category",
            "Product Details": "Product Details",
            "Product Info": "Product Info",
            "Attributes Detail": "Attributes Detail",
            "icon": "icon",
            "value": "value",
            "Supplier Info": "Supplier Info",
            "Address": "Address",
            "View Location": "View Location",
            "photo": "Photo",
            "Title": "Title",
            "Manage Categories": "Manage Categories",
            "Manage Sub Categories": "Manage Sub Categories",
            "Category Name": "Category Name",
            "Sub Category Name": "Sub Category Name",
            "Sub Category": "Sub Category",
            "View Sub-Category": "View Sub-Category",
            "Manage Content": "Manage Content",
            "Content Name": "Content Name",
            "Coupon": "Coupon",
            "Discount Coupons": "Discount Coupons",
            "Offer Name": "Offer Name",
            "Coupon Code": "Coupon Code",
            "Discount Applied": "Discount Applied",
            "Percentage": "Percentage",
            "Flat": "Flat",
            "Minimum Use": "Minimum Use",
            "Discount Name": "Discount Name",
            "Discount Code": "Discount Code",
            "Start Date": "Start Date",
            "End Date": "End Date",
            "Value": "Value",
            "Customer": "Customer",
            "Manage Customers": "Manage Customers",
            "Add New Product": "Add New Product",
            "Add New Service": "Add New Service",
            "Availability": "Availability",
            "TREE TRIMMING": "TREE TRIMMING",
            "GRASS TRIMMING": "GRASS TRIMMING",
            "SHORT": "SHORT",
            "MEDIUM": "MEDIUM",
            "SHORT MEDIUM": "SHORT MEDIUM",
            "LONG": "LONG",
            "NONE": "NONE",
            "ordering": "Ordering",
            "Range From": "Range From",
            "Range To": "Range To",
            "Tree Type": "Tree Type",
            "Edit Self Calculation": "Edit Self Calculation",
            "Manage Products And Services": "Manage Products And Services",
            "Reports": "Reports",
            "Type": "Type",
            "Customer Report": "Customer Report",
            "Supplier Report": "Supplier Report",
            "Products Sales Report": "Products Sales Report",
            "Service Sales Report": "Service Sales Report",
            "Total Sales Report": "Total Sales Report",
            "Supplier Credit Balance Report": "Supplier Credit Balance Report",
            "Product Orders Report": "Product Orders Report",
            "Service Orders Report": "Service Orders Report",
            "Generate": "Generate",
            "Year": "Year",
            "Month": "Month",
            "Daily": "Daily",
            "Yearly": "Yearly",
            "Monthly": "Monthly",
            "January": "January",
            "February": "February",
            "March": "March",
            "April": "April",
            "May": "May",
            "June": "June",
            "July": "July",
            "August": "August",
            "September": "September",
            "October": "October",
            "November": "November",
            "December": "December",
            "Parameter": "Parameter",
            "Include": "(Include)",
            "Role": "Role",
            "Password": "Password",
            "Pending": "Pending",
            "Manage Suppliers": "Manage Suppliers",
            "Supplier Not Found": "Supplier Not Found",
            "Phone Number": "Phone Number",
            "Commercial Registration No": "Commercial Registration No",
            "select address": "select address",
            "Type Here": "Type Here...",
            "Add Supplier": "Add Supplier",
            "Service": "Service",
            "Manage Transactions": "Manage Transactions",
            "Created At": "Created At",
            "Withdrawal Request Report": "Withdrawal Request Report",
            "url": "url",
            "Add Photo": "Add Photo",
            "Contact Number": "Contact Number",
            "Message": "Message",
            "Manage Notifications": "Manage Notifications",
            "Send By": "Send By",
            "Text": "Text",
            "Send Notification": "Send Notification",
            "Admin": "Admin",
            "View History": "View History",
            "To": "To",
            "Send": "Send",
            "SPECIFIC": "SPECIFIC",
            "ALL": "ALL",
            "Customers": "Customers",
            "Suppliers": "Suppliers",
            "User Type": "User Type",
            "Send To": "Send To",
            "Product Delivery Charges (SAR)": "Product Delivery Charges (SAR)",
            "Setting": "Setting",
            "Sorry, you don't have permission": "Sorry, you don't have permission",
            "User Not Available": "User Not Available",
            "Banner Not Available": "Banner Not Available",
            "Record Not Available": "Record Not Available",
            "Faqs Not Available": "Faqs Not Available",
            "Role Not Available": "Role Not Available",
            "Product Not Available For Approval": "Product Not Available For Approval",
            "Category Not Available": "Category Not Available",
            "Sub Category Not Available": "Sub Category Not Available",
            "Coupon Not Available": "Coupon Not Available",
            "Service Not Available": "Service Not Available",
            "Product Not Available": "Product Not Available",
            "Customer Not Available": "Customer Not Available",
            "Earnings Not Available": "Earnings Not Available",
            "Supplier Not Available": "Supplier Not Available",
            "History Not Available": "History Not Available",
            "Notification Not Available": "Notification Not Available",
            "Service Order Not Available": "Service Order Not Available",
            "Transaction Not Available": "Transaction Not Available",
            "Withdrawal Request Not Found": "Withdrawal Request Not Found",
            "Edit Supplier": "Edit Supplier",
            "Basic Info": "Basic Info",
            "Fees": "Fees",
            "Service Orders": "Service Orders",
            "Product Commission Type": "Product Commission Type",
            "Select Product Commission Type": "Select Product Commission Type",
            "Fixed": "Fixed",
            "Admin Product Fees": "Admin Product Fees",
            "Service Commission Type": "Service Commission Type",
            "Select Service Commission Type": "Select Service Commission Type",
            "Admin Service Fees": "Admin Service Fees",
            "Product Delivery Charges(SAR)": "Product Delivery Charges(SAR)",
            "Has Minimum Order For Free Shipping": "Has Minimum Order For Free Shipping",
            "Minimum Order For Free Shipping": "Minimum Order For Free Shipping",
            "Your Earning": "Your Earning",
            "Total Order Amount": "Total Order Amount",
            "Earning Amount": "Earning Amount",
            "OrderId": "OrderId",
            "Basic Information": "Basic Information",
            "Edit Customer": "Edit Customer",
            "Language": "Language",
            "English": "English",
            "Arabic": "Arabic",
            "Welcome Wallet Benefit": "Welcome Wallet Benefit",
            "Previous Visit Discount": "Previous Visit Discount",
            "Search Distance": "Search Distance",
            "Update Commisssion for All Suppliers": "Update Commisssion for All Suppliers",
            "Are you sure, want to update commisssion for all suppliers": "Are you sure, want to update commisssion for all suppliers",
            "Confirmation": "Confirmation",
            "Company Registration Number": "Company Registration Number",
            "Old Password": "Old Password",
            "New Work Order": "New Work Order",
            "New Order": "New Order",
            "Create New Order": "Create New Order",
            "Preferred Time": "Preferred Time",
            "Time Needed": "Time Needed",
            "Edit Order": "Edit Order",
            "Please enter price": "Please enter price",
            "Please enter description": "Please enter description",
            "Please enter time needed": "Please enter time needed",
            "Please select preferred date": "Please select preferred date",
            "SubTotal": "SubTotal",
            "06 AM TO 10 AM": "06 AM TO 10 AM",
            "10 AM TO 02 PM": "10 PM TO 02 PM",
            "02 PM TO 06 PM": "02 PM TO 06 PM",
            "06 PM TO 10 PM": "06 PM TO 10 PM",
            "Admin Commission": "Admin Commission",
            "Cash On Delivery": "Cash On Delivery",
            "Net Banking": "Net Banking",
            "Card Payment": "Card Payment",
            "PayPal": "PayPal",
            "Cash": "Cash",
            "Success": "Success",
            "Failed": "Failed",
            'Pending at supplier': 'Pending at supplier',
            'Pending at customer': 'Pending at customer',
            'Payment Pending': 'Payment Pending',
            "From Date": "From Date",
            "To Date": "To Date",
            "Submit": "Submit",
            "Update": "Update",
            "Order Type":"Order Type",
            "No record found":"No record found",
            "Commission":"Commission",
            "Order Amount":"Order Amount",
            "Including Tax":"Including Tax",
            "Inspection Discount":"Inspection Discount",
            "Additional information":"Additional information",
            "Extra Note":"Extra Note",
            "Order Number":"Order No",
            "Visit Charges":"Visit Charges",
            "company_images": "Images"
        },

    },
    ar: {
        label: {
            "Dashboard": "لوحة القيادة",
            "Services": "خدمات",
            "Products": "منتجات",
            "Product": "منتجات",
            "All Products": "جميع المنتجات",
            "Product Orders": "طلبات المنتجات",
            "All Services": "جميع الخدمات",
            "Services Orders": "طلبات الخدمات",
            "Product Delivery Charges": "رسوم توصيل المنتج",
            "Earnings": "الأرباح",
            "Manage Service Orders": "إدارة طلبات الخدمة",
            "Manage Product Orders": "إدارة طلبات المنتجات",
            "Manage Products": "إدارة المنتجات",
            "Manage Services": "إدارة الخدمات",
            "Show More": "أظهر المزيد",
            "My Profile": "ملفي",
            "Company Profile": "ملف الشركة",
            "Settings": "إعدادات",
            "Terms & Condition": "الشروط والأحكام",
            "Logout": "تسجيل خروج",
            "search here": "ابحث هنا...",
            "Approved": "موافقة",
            "Requested": "مطلوب",
            "Rejected": "مرفوض",
            "Status": "الحالة",
            "Change Password": "تغيير كلمة المرور",
            "Current Password": "كلمة المرور الحالية",
            "New Password": "كلمة المرور الجديدة",
            "Confirm Password": "تأكيد كلمة المرور",
            "Save": "حفظ",
            "Cancel": "إلغاء",
            "Company Details": "تفاصيل الشركة",
            "Phone": "الهاتف",
            "Email": "البريد الإلكتروني",
            "Company Name": "اسم الشركة",
            "Change": "يتغير",
            "Change Logo": "تغيير الشعار",
            "images": "الصور",
            "All Withdrawal": "جميع السحب",
            "Add Image": "إضافة صورة",
            "Action": "الإجراء",
            "Upload Image": "تحميل الصورة",
            "Upload a image": "تحميل الصورة",
            "(Max 10 mb)": "(الحد الأقصى 10 ميغابايت)",
            "(Optional)": "(اختياري)",
            "change Image": "تغيير الصورة",
            "Close": "إغلاق",
            "Delete": "يمسح",
            "Are you sure you want to delete": "هل أنت متأكد أنك تريد حذف",
            "Invalid file formate, please use jpeg or png file.": "تنسيق ملف غير صالح، يرجى استخدام ملف jpeg أو png.",
            "File must be less then 10 MB": "يجب أن يكون حجم الملف أقل من 10 ميغابايت",
            "Withdrawal Request & Date Time": "طلب السحب والتاريخ والوقت",
            "Payment Amount": "مبلغ الدفع",
            "Withdrawal Status": "حالة السحب",
            "Order No. #": "رقم الطلب #",
            "All Earnings": "جميع الأرباح",
            "Total Order Payment": "مجموع دفع الطلب",
            "Your Earnings": "أرباحك",
            "Reason": "السبب",
            "Back": "العودة",
            "Cancel Request": "إلغاء الطلب",
            "Withdrawal Request ID": "معرف طلب السحب",
            "Date": "التاريخ",
            "Time": "الوقت",
            "Process may take up to 24 hours. Please contact us if the withdrawal is not completed within this period": "قد يستغرق العملية حتى 24 ساعة. يرجى الاتصال بنا إذا لم يتم الانسحاب خلال هذه الفترة",
            "The money will be credited to your account within 1 minute to 3 working days": "سيتم تحويل الأموال إلى حسابك في غضون 1 دقيقة إلى 3 أيام عمل",
            "Withdrawal Detail": "تفاصيل السحب",
            "Notifications": "الإشعارات",
            "No notification available": "لا تتوفر إشعارات",
            "Withrawal Request Successful": "طلب السحب ناجح",
            "OK": "حسنًا",
            "Request": "طلب",
            "Amount to Withdrawal": "المبلغ للسحب",
            "Select Order": "اختر الطلب",
            "Request for Withdrawal": "طلب السحب",
            "Select Banks": "اختر البنوك",
            "Select": "اختيار",
            "Accoount Number": "رقم الحساب",
            "Confirm Account Number": "تأكيد رقم الحساب",
            "IBAN Number": "رقم IBAN",
            "Mobile Number": "رقم الهاتف المحمول",
            "Name as given in bank": "الاسم كما هو مذكور في البنك",
            "Minimum Order Free Shipping": "الحد الأدنى للطلب للشحن مجانًا",
            "Profile": "الملف الشخصي",
            "Operating Area": "منطقة التشغيل",
            "Change photo": "تغيير الصورة",
            "Name": "الاسم",
            "Work Address": "عنوان العمل",
            "Add": "إضافة",
            "Add More": "إضافة المزيد",
            "Are you sure you want to delete this address": "هل أنت متأكد أنك تريد حذف هذا العنوان",
            "Please enter operating area": "يرجى إدخال منطقة التشغيل",
            "Operating Area/Region": "منطقة التشغيل/المنطقة",
            "Add Products": "إضافة منتجات",
            "Product image": "صورة المنتج",
            "Product Name": "اسم المنتج",
            "Description": "الوصف",
            "Category": "الفئة",
            "Sub-Category": "الفئة الفرعية",
            "Price": "السعر",
            "Details": "التفاصيل",
            "Out of stock": "نفذ من المخزون",
            "Edit Products": "تحرير المنتجات",
            "Product Description": "وصف المنتج",
            "Gardening Catelog Products": "منتجات كتالوج الحدائق",
            "My Products": "منتجاتي",
            "Request for New Product": "طلب منتج جديد",
            "Image": "صورة",
            "Added": "تمت الإضافة",
            "Save & Add": "حفظ وإضافة",
            "No product available": "لا يتوفر منتج",
            "Gardening Catelog Services": "خدمات كتالوج الحدائق",
            "My Services": "خدماتي",
            "Service Name": "اسم الخدمة",
            "Visit Price": "سعر الزيارة",
            "No service available": "لا تتوفر خدمة",
            "Edit Service": "تحرير الخدمة",
            "Service Image": "صورة الخدمة",
            "Service Description": "وصف الخدمة",
            "Allow Self Calculation": "السماح بالحساب الذاتي",
            "Price for Small/Medium Tree": "السعر للشجرة الصغيرة/المتوسطة",
            "Price for Large Tree": "السعر للشجرة الكبيرة",
            "Order Detail": "تفاصيل الطلب",
            "Order Id: #": "رقم الطلب: #",
            "Location": "الموقع",
            "Date & Time": "التاريخ والوقت",
            "Completed": "مكتمل",
            "Cancelled": "ملغاة",
            "Accepted": "مقبولة",
            "Payment Status": "حالة الدفع",
            "S_No": "الرقم",
            "Quantity": "الكمية",
            "Amount": "المبلغ",
            "Accept": "قبول",
            "Reject": "رفض",
            "Mark Delivery Complete": "وضع علامة على تسليم كامل",
            "Total": "الإجمالي",
            "Delivery Charges": "رسوم التوصيل",
            "VAT": "القيمة المضافة",
            "Discount": "الخصم",
            "Sub Total": "المجموع الفرعي",
            "Customer Name": "اسم العميل",
            "Preferred Date": "التاريخ المفضل",
            "Order ID": "رقم الطلب",
            "Manage Products Orders": "إدارة طلبات المنتجات",
            "Cancelled Orders": "طلبات ملغاة",
            "Delivered Orders": "طلبات تم توصيلها",
            "Rejected Orders": "طلبات مرفوضة",
            "Accepted Orders": "طلبات مقبولة",
            "Pending Orders": "طلبات معلقة",
            "Completed Orders": "طلبات مكتملة",
            "Tax": "الضريبة",
            "Manage Services Orders": "إدارة طلبات الخدمات",
            "Note": "ملاحظة",
            "Pending at Supplier": "معلقة في المورد",
            "Preferred Date & Time": "التاريخ والوقت المفضلين",
            "Supplier Detail": "تفاصيل المورد",
            "Transaction Detail": "تفاصيل العملية",
            "Transaction Amount": "مبلغ العملية",
            "Transaction Id": "معرف العملية",
            "Transaction Date & Time": "تاريخ ووقت العملية",
            "Payment Mode": "طريقة الدفع",
            "Order Status": "حالة الطلب",
            "Select Supplier": "اختيار المورد",
            "Sr_No": "الرقم التسلسلي",
            "Order Not Available": "الطلب غير متوفر",
            "New Work Complete": "العمل الجديد مكتمل",
            "New Work Under Progress": "العمل الجديد قيد التقدم",
            "Pending New Work": "العمل الجديد معلق",
            "Visit Complete": "الزيارة مكتملة",
            "Outgoing": "صادر",
            "New Visit": "زيارة جديدة",
            "Supplier Name": "اسم المورد",
            "Manage Admins": "إدارة المشرفين",
            "Admins": "المشرفين",
            "Manage Banners": "إدارة اللافتات",
            "Upload Banner": "تحميل اللافتة",
            "Url": "رابط",
            "Manage Helps": "إدارة المساعدة",
            "FAQ": "أسئلة متكررة",
            "Question": "السؤال",
            "Answer": "الجواب",
            "Manage FAQ": "إدارة الأسئلة الشائعة",
            "Manage Roles": "إدارة الأدوار",
            "Roles": "الأدوار",
            "Add Password": "إضافة كلمة المرور",
            "Access to": "الوصول إلى",
            "View": "عرض",
            "Edit": "تحرير",
            "Manage Products Approval": "إدارة موافقة المنتجات",
            "Select Category": "اختيار الفئة",
            "All Category": "كل الفئات",
            "Select Sub-Category": "اختيار الفئة الفرعية",
            "Product Details": "تفاصيل المنتج",
            "Product Info": "معلومات المنتج",
            "Attributes Detail": "تفاصيل السمات",
            "icon": "أيقونة",
            "value": "قيمة",
            "Supplier Info": "معلومات المورد",
            "Address": "العنوان",
            "View Location": "عرض الموقع",
            "photo": "صورة",
            "Title": "العنوان",
            "Manage Categories": "إدارة الفئات",
            "Manage Sub Categories": "إدارة الفئات الفرعية",
            "Category Name": "اسم الفئة",
            "Sub Category Name": "اسم الفئة الفرعية",
            "Sub Category": "تصنيف فرعي",
            "View Sub-Category": "عرض الفئة الفرعية",
            "Manage Content": "إدارة المحتوى",
            "Content Name": "اسم المحتوى",
            "Coupon": "قسيمة",
            "Discount Coupons": "قسائم الخصم",
            "Offer Name": "اسم العرض",
            "Coupon Code": "رمز القسيمة",
            "Discount Applied": "الخصم المطبق",
            "Percentage": "النسبة المئوية",
            "Flat": "ثابت",
            "Minimum Use": "الاستخدام الأدنى",
            "Discount Name": "اسم الخصم",
            "Discount Code": "رمز الخصم",
            "Start Date": "تاريخ البدء",
            "End Date": "تاريخ الانتهاء",
            "Value": "القيمة",
            "Customer": "العميل",
            "Manage Customers": "إدارة العملاء",
            "Add New Product": "إضافة منتج جديد",
            "Add New Service": "إضافة خدمة جديدة",
            "Availability": "التوافر",
            "TREE TRIMMING": "تقليم الأشجار",
            "GRASS TRIMMING": "تقليم العشب",
            "SHORT": "قصير",
            "MEDIUM": "متوسط",
            "SHORT MEDIUM": "قصير متوسط",
            "LONG": "طويل",
            "NONE": "لا شيء",
            "ordering": "طلب",
            "Range From": "النطاق من",
            "Range To": "النطاق إلى",
            "Tree Type": "نوع الشجرة",
            "Edit Self Calculation": "تحرير الحساب الذاتي",
            "Manage Products And Services": "إدارة المنتجات والخدمات",
            "Reports": "التقارير",
            "Type": "النوع",
            "Customer Report": "تقرير العميل",
            "Supplier Report": "تقرير المورد",
            "Products Sales Report": "تقرير مبيعات المنتجات",
            "Service Sales Report": "تقرير مبيعات الخدمات",
            "Total Sales Report": "تقرير إجمالي المبيعات",
            "Supplier Credit Balance Report": "تقرير رصيد الائتمان للمورد",
            "Product Orders Report": "تقرير طلبات المنتجات",
            "Service Orders Report": "تقرير طلبات الخدمات",
            "Generate": "توليد",
            "Year": "السنة",
            "Month": "الشهر",
            "Daily": "يومياً",
            "Yearly": "سنوياً",
            "Monthly": "شهرياً",
            "January": "يناير",
            "February": "فبراير",
            "March": "مارس",
            "April": "أبريل",
            "May": "مايو",
            "June": "يونيو",
            "July": "يوليو",
            "August": "أغسطس",
            "September": "سبتمبر",
            "October": "أكتوبر",
            "November": "نوفمبر",
            "December": "ديسمبر",
            "Parameter": "المعلمة",
            "Include": "(تضمين)",
            "Role": "دور",
            "Password": "كلمة المرور",
            "Pending": "قيد الانتظار",
            "Manage Suppliers": "إدارة الموردين",
            "Supplier Not Found": "لم يتم العثور على المورد",
            "Phone Number": "رقم التليفون",
            "Commercial Registration No": "رقم السجل التجاري",
            "select address": "حدد العنوان",
            "Type Here": "أكتب هنا...",
            "Add Supplier": "إضافة مورد",
            "Service": "خدمة",
            "Manage Transactions": "إدارة المعاملات",
            "Created At": "أنشئت في",
            "Withdrawal Request Report": "تقرير طلب السحب",
            "url": "عنوان URL",
            "Add Photo": "إضافة صورة",
            "Contact Number": "رقم الاتصال",
            "Message": "رسالة",
            "Manage Notifications": "إدارة الإخطارات",
            "Send By": "أرسل بواسطة",
            "Text": "نص",
            "Send Notification": "إرسال الإخطار",
            "Admin": "مسؤل",
            "View History": "عرض السجل",
            "To": "ل",
            "Send": "يرسل",
            "SPECIFIC": "محدد",
            "ALL": "الجميع",
            "Customers": "عملاء",
            "Suppliers": "الموردين",
            "User Type": "نوع المستخدم",
            "Send To": "ارسل إلى",
            "Product Delivery Charges (SAR)": "رسوم توصيل المنتج (ريال سعودي)",
            "Setting": "جلسة",
            "Sorry, you don't have permission": "آسف، ليس لديك إذن",
            "User Not Available": "المستخدم غير متوفر",
            "Banner Not Available": "اللافتة غير متوفرة",
            "Record Not Available": "السجل غير متوفر",
            "Faqs Not Available": "الأسئلة الشائعة غير متوفرة",
            "Role Not Available": "الدور غير متاح",
            "Product Not Available For Approval": "المنتج غير متاح للموافقة عليه",
            "Category Not Available": "الفئة غير متوفرة",
            "Sub Category Not Available": "الفئة الفرعية غير متوفرة",
            "Coupon Not Available": "القسيمة غير متوفرة",
            "Service Not Available": "الخدمة غير متوفرة",
            "Product Not Available": "المنتج غير متوفر",
            "Customer Not Available": "العميل غير متوفر",
            "Earnings Not Available": "الأرباح غير متوفرة",
            "Supplier Not Available": "المورد غير متوفر",
            "History Not Available": "التاريخ غير متوفر",
            "Notification Not Available": "الإخطار غير متوفر",
            "Service Order Not Available": "طلب الخدمة غير متوفر",
            "Transaction Not Available": "المعاملة غير متوفرة",
            "Withdrawal Request Not Found": "لم يتم العثور على طلب السحب",
            "Edit Supplier": "تحرير المورد",
            "Basic Info": "معلومات أساسية",
            "Fees": "مصاريف",
            "Service Orders": "أوامر الخدمة",
            "Product Commission Type": "نوع عمولة المنتج",
            "Select Product Commission Type": "حدد نوع عمولة المنتج",
            "Fixed": "مُثَبَّت",
            "Admin Product Fees": "رسوم المنتج الادارية",
            "Service Commission Type": "نوع عمولة الخدمة",
            "Select Service Commission Type": "اختر نوع عمولة الخدمة",
            "Admin Service Fees": "رسوم الخدمة الإدارية",
            "Product Delivery Charges(SAR)": "رسوم توصيل المنتج (ريال سعودي)",
            "Has Minimum Order For Free Shipping": "لديه الحد الأدنى للطلب للشحن المجاني",
            "Minimum Order For Free Shipping": "الحد الأدنى للطلب للشحن المجاني",
            "Your Earning": "كسبك",
            "Total Order Amount": "إجمالي مبلغ الطلب",
            "Earning Amount": "مبلغ الكسب",
            "OrderId": "رقم التعريف الخاص بالطلب",
            "Basic Information": "معلومات اساسية",
            "Edit Customer": "تحرير العميل",
            "Language": "لغة",
            "English": "إنجليزي",
            "Arabic": "عربي",
            "Welcome Wallet Benefit": "مرحباً بمزايا المحفظة",
            "Previous Visit Discount": "خصم الزيارة السابقة",
            "Search Distance": "مسافة البحث",
            "Update Commisssion for All Suppliers": "لجنة التحديث لجميع الموردين",
            "Are you sure, want to update commisssion for all suppliers": "هل أنت متأكد أنك تريد تحديث العمولة لجميع الموردين",
            "Confirmation": "تأكيد",
            "Company Registration Number": "رقم تسجيل الشركة",
            "Old Password": "كلمة المرور القديمة",
            "New Work Order": "أمر عمل جديد",
            "New Order": "طلب جديد",
            "Create New Order": "إنشاء طلب جديد",
            "Preferred Time": "الوقت المفضل",
            "Time Needed": "الوقت اللازم",
            "Edit Order": "تحرير الطلب",
            "Please enter price": "الرجاء إدخال السعر",
            "Please enter description": "الرجاء إدخال الوصف",
            "Please enter time needed": "الرجاء إدخال الوقت اللازم",
            "Please select preferred date": "الرجاء تحديد التاريخ المفضل",
            "SubTotal": "المجموع الفرعي",
            "06 AM TO 10 AM": "06 صباحًا إلى 10 صباحًا إلى",
            "10 AM TO 02 PM": "10 مساءً إلى 02 صباحًا إلى",
            "02 PM TO 06 PM": "02 مساءً إلى 06 مساءً",
            "06 PM TO 10 PM": "06 مساءً إلى 10 مساءً",
            "Admin Commission": "اللجنة الادارية",
            "Cash On Delivery": "الدفع عند الاستلام",
            "Net Banking": "صافي المصرفية",
            "Card Payment": "بطاقه ائتمان",
            "PayPal": "باي بال",
            "Cash": "نقدي",
            "Success": "نجاح",
            "Failed": "فشل",
            'Pending at supplier': 'في انتظار المورد',
            'Pending at customer': 'في انتظار العملاء',
            'Payment Pending': 'انتظار الدفع',
            "From Date": "من التاريخ",
            "To Date": "ان يذهب في موعد",
            "Update": "تحديث",
            "Submit": "يُقدِّم",
            "Order Type":"نوع الطلب",
            "No record found":"لا يوجد سجلات",
            "Commission":"عمولة",
            "Order Amount":"كمية الطلب",
            "Including Tax":"متضمن للضريبة",
            "Inspection Discount":"خصم التفتيش",
            "Additional information":"معلومات إضافية",
            "Extra Note":"ملاحظة إضافية",
            "Order Number":"رقم الطلب",
            "Visit Charges"  : "رسوم الزيارة",
            "company_images": "الصور"


        },

    },
};

export default messages;