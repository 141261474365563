<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row p-3 align-items-center">
                <div class="col-md-6 col-5">
                  <h2 class="page-title mb-md-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ $t("label.View History") }}
                  </h2>
                </div>
              </div>
              <table
                class="table table-style-1 table-responsive"
                id="sorting_tbl"
              >
                <thead>
                  <tr class="row col 12">
                    <th class="col-4">{{ $t("label.S_No") }}</th>
                    <th class="col-4">{{ $t("label.Date & Time") }}</th>
                    <th class="col-4">{{ $t("label.To") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="row col 12"
                    v-for="(data, index) in historyList"
                    :key="index"
                  >
                    <td class="col-4">{{ index + 1 }}</td>
                    <td class="col-4">{{ data.sent_at }}</td>
                    <td class="col-4">{{ data.user.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- <div class="row mb-4">
            <div class="col-lg-12 mt-3">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div> -->
          <!-- <ErrorComponent @retry="getHistoryList(0)" ref="errorComponent" /> -->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
// import ErrorComponent from "@/components/ErrorComponent";
// import Pagination from "@/components/Pagination.vue";
export default {
  name: "ViewHistory",

  components: {
    // ErrorComponent,
    // Pagination,
  },

  data() {
    return {
      id: this.$route.params.id,
      detail: {},
      historyList: [],

      searchObj: {
        keyword: "",
      },
      loader: true,
      offset: 0,
      itemsPerPage: "",
    };
  },

  mounted() {
    this.getHistoryList(0);
  },

  methods: {
    // pageChange(offset) {
    //   this.getHistoryList(offset - 1);
    // },
    // itemsPerPageChange() {
    //   this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
    //   this.getHistoryList(0);
    // },
    getHistoryList(offset) {
      // this.loader = true;
      // if (this.$refs.errorComponent) {
      //   this.$refs.errorComponent.updateLoader(true);
      // }
      var obj = {};
      if (offset == 0) {
        this.historyList = [];
      }
      obj.offset = offset;
      this.$api
        .getAPI({
          _action: "notification/" + this.id,
        })
        .then((res) => {
          this.loader = false;
          this.detail = res.info;

          for (var k in res.info.notified_users) {
            res.info.notified_users[k].sent_at = moment(
              res.info.notified_users[k].sent_at
            ).format("L LTS");
          }
          this.historyList = res.info.notified_users;
          // this.offset = res.next_offset;
          // if (this.offset >= 0) {
          //   if (this.$refs.listPagination) {
          //     if (res.count != null && res.count > 0) {
          //       this.$refs.listPagination.setTotalCount(res.count);
          //     } else {
          //       this.$refs.listPagination.setTotalCount(0);
          //     }
          //   }
          // }
          // if (this.$refs.errorComponent) {
          //   this.$refs.errorComponent.setData(res, this.$t("label.History Not Available"));
          //   this.$refs.errorComponent.updateLoader(false);
          // }
        })
        .catch(() => {
          // this.loader = false;
          // this.$refs.errorComponent.setData(0, undefined, "");
          // this.$refs.errorComponent.updateLoader(false);
        });
    },
  }
};
</script>

