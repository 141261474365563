<template>
  <div class="d-lg-flex no-right-sidebar" id="main-wraper">
    <div class="page-wrapper no-right-sidebar">
      <div class="page-content">
        <div class="row mb-4 justify-content-center">
          <div class="col-lg-12">
            <div class="row p-3 align-items-center text-dark">
              <div class="col-md-6 col-5">
                <h2 class="page-title mb-md-0">
                  <a
                    href="javascript:void(0)"
                    v-on:click="$router.go(-1)"
                    class="back-arrow"
                  >
                    <i class="bx bx-arrow-back"></i
                  ></a>
                  {{ id ? $t("label.Edit") : $t("label.Add") }} {{ $t("label.FAQ") }}
                </h2>
              </div>
            </div>
            <div
              class="card card-body content-card mb-4 rounded-l border-0 shadow-sm"
              style="width: 120%"
            >
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <form class="form-style">
                    <Form class="form-style floating-form" @submit="update()">
                      <div class="row justify-content-center mb-3">
                        <div class="col-md-10">
                          <div class="row">
                            <div class="col-md-12">
                              <label for="question">{{ $t("label.Question") }}</label>
                              <span class="text-danger">*</span>
                              <textarea
                                type="text"
                                id="question"
                                name="question"
                                :placeholder="$t('label.Question')"
                                v-model="detail.question"
                                class="form-control filled"
                                rules="required:question"
                                :validateOnInput="true"
                              />
                              <ErrorMessage name="question" class="validation-msg" />
                            </div>
                            <div class="col-md-12 mt-3">
                              <label for="area">{{ $t("label.Answer") }}</label>
                              <span class="text-danger">*</span>
                              <editor
                                api-key="no-api-key"
                                placeholder="Answer"
                                v-model="detail.answer"
                              ></editor>
                            </div>
                            <ErrorMessage name="answer" class="validation-msg" />
                          </div>
                        </div>
                      </div>
                      <div class="text-center mt-3">
                        <button
                          type="button"
                          class="btn btn-brand-1 mb-3 mr-2 px-5"
                          v-on:click="$router.go(-1)"
                        >
                          {{ $t("label.Cancel") }}
                        </button>
                        <button class="btn btn-brand-1 mb-3 px-5" id="save-button">
                          {{ $t("label.Save") }}
                        </button>
                      </div>
                    </Form>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import { Form, ErrorMessage } from "vee-validate";
export default {
  name: "AddFAQ",
  components: {
    Form,

    ErrorMessage,
    Editor,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    update() {
      if (this.id) {
        this.$api
          .putAPI({
            _action: "faq/" + this.id,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "faq",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "faq/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },
  },
};
</script>

