<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row p-3 align-items-center">
                <div class="col-md-6 col-5">
                  <h2 class="page-title mb-md-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ $t("label.Manage Banners") }}
                  </h2>
                </div>
                <div class="col-md-6 col-7 text-right d-md-flex justify-content-end mb-3">
                  <button
                    type="button"
                    class="btn btn-brand-1 btn-sm"
                    v-on:click="$router.push('/add-banner')"
                    v-if="access.can_create"
                  >
                    <i class="bx bx-plus"></i> {{ $t("label.Add") }}
                  </button>
                </div>
              </div>
              <div
                class="row mb-md-4 mb-3 align-items-center justify-content-between filter-options"
              ></div>
              <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
                <table class="table-style-1 table table-responsive" id="sorting_tbl">
                  <thead>
                    <tr>
                      <th class="sorting cursor-pointer">{{ $t("label.S_No") }}</th>
                      <th>{{ $t("label.Image") }}</th>
                      <th>{{ $t("label.url") }}</th>
                      <th>{{ $t("label.Action") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in imageList" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        <img
                          :src="data.banner ? data.banner : '/images/placeholder.png'"
                          class="border border-light"
                          style="border-width: 3px !important"
                          alt="image thumb"
                          width="130"
                          height="130"
                        />
                      </td>
                      <td>
                        <a
                          :href="data?.url"
                          class=""
                          target="_blank
                      "
                        >
                          {{ data?.url ? data?.url : "-" }}
                        </a>
                      </td>
                      <td>
                        <button
                          class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                          v-on:click="showDeletePopup(data.id)"
                          v-if="access.can_delete"
                        >
                          <i class="bx bxs-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ErrorComponent @retry="getImageList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-lg-12 mt-3">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
</template>

<script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "ManageAdmins",

  components: {
    DeleteModal,
    ErrorComponent,
    Pagination,
    //  Form,
    //  Field,
    // ErrorMessage
  },
  data() {
    return {
      imageList: [],
      searchObj: {
        keyword: "",
        offset: "0",
      },
      currentPage: 1,
      loader: true,
      itemsPerPage: 10,
      token: localStorage.getItem("access_token"),
      access: { can_create: 1, can_update: 1, can_read: 1, can_delete: 1 },
    };
  },
  mounted() {
    this.getImageList(0);
  },
  methods: {
    pageChange(page) {
      this.getImageList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getImageList(1);
    },
    getImageList(page, sortBy, orderBy) {
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.searchObj.sort_order = orderBy;
      }
      if (!page) {
        page = 1;
      }
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      // this.searchObj.row_per_page = this.itemsPerPage;

      this.imageList = [];
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: "banners",
          _body: this.searchObj,
        })
        .then((res) => {
          this.imageList = res.list;
          this.access = res.manage_banners;
          this.searchObj.offset = res.next_offset;

          
          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
            if (res.count != null && res.count > 0 && this.access.can_read) {
              this.$refs.listPagination.setTotalCount(res.count);
            } else {
              this.$refs.listPagination.setTotalCount(0);
            }
          }

          if (this.$refs.errorComponent) {
            if (this.access.can_read == 0) {
              this.$refs.errorComponent.setData(res, this.$t("label.Sorry, you don't have permission"));
              this.$refs.errorComponent.updateLoader(false);
              return;
            }
            this.$refs.errorComponent.setData(res, this.$t("label.Banner Not Available"));
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        this.$t("label.Delete"),
        this.$t("label.Are you sure you want to delete"),
        id
      );
    },
    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "banner/" + id,
        })
        .then((res) => {
          this.getImageList(0);
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.deleteModal.closeModal();
        })
        .catch(() => {});
    },
  },
};
</script>

