<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row mt-3 p-3 align-items-center">
                <div class="col-md-6">
                  <h2 class="page-title mb-md-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ $t("label.Manage Transactions") }}
                  </h2>
                </div>
                <div class="col-md-6 text-right d-md-flex justify-content-end mb-3">
                  <!-- <button
                    type="button"
                    class="btn btn-brand-1 btn-sm"
                    v-on:click="router()"
                  >
                    <i class="bx bx-plus"></i> Add
                  </button> -->
                </div>
              </div>

              <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pending_orders"
                  role="tabpanel"
                  aria-labelledby="pending_orders-tab"
                >
                  <div
                    class="row mb-md-4 mb-3 align-items-center justify-content-between filter-options"
                  >
                    <div class="col-lg-3 col-md-4 d-md-flex mb-md-0 mb-2">
                      <div class="input-group page-cont-search">
                        <span class="input-group-text"><i class="bx bx-search"></i></span>
                        <input
                          type="text"
                          class="form-control"
                          name=""
                          v-on:keyup.enter="getTransactionList(0)"
                          :placeholder="$t('label.search here')"
                          v-model="searchObj.keyword"
                        />
                      </div>
                    </div>
                  </div>

                  <table class="table table-style-1 table-responsive" id="sorting_tbl">
                    <thead>
                      <tr>
                        <th class="sorting cursor-pointer" id="id-title">
                          {{ $t("label.Customer Name") }}
                        </th>

                        <th class="sorting cursor-pointer" id="id-sub-category">
                          {{ $t("label.Payment Mode") }}
                        </th>
                        <th class="sorting cursor-pointer" id="id-sub-category">
                          {{ $t("label.Transaction Id") }}
                        </th>
                        <th class="sorting cursor-pointer" id="id-category">
                          {{ $t("label.Transaction Amount") }}
                        </th>
                        <th class="sorting cursor-pointer" id="id-sub-category">
                          {{ $t("label.Created At") }}
                        </th>
                        <th>{{ $t("label.Action") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in TransactionList" :key="index">
                        <td>
                          {{ data?.users?.name }}
                        </td>

                        <td>
                          <span
                            class="font-bold font-16"
                            v-if="data?.payment_mode == 'Cash On Delivery'"
                            >{{ $t("label.Cash On Delivery") }}</span
                          >
                          <span
                            class="font-bold font-16"
                            v-if="data?.payment_mode == 'PayPal'"
                            >{{ $t("label.PayPal") }}</span
                          >
                          <span
                            class="font-bold font-16"
                            v-if="data?.payment_mode == 'Card Payment'"
                            >{{ $t("label.Card Payment") }}</span
                          >
                          <span
                            class="font-bold font-16"
                            v-if="data?.payment_mode == 'Net Banking'"
                            >{{ $t("label.Net Banking") }}</span
                          >
                          <span
                            class="font-bold font-16"
                            v-if="data?.payment_mode == 'Cash '"
                            >{{ $t("label.Cash ") }}</span
                          >
                        </td>
                        <td>{{ data?.trans_id }}</td>
                        <td>
                          {{ data?.trans_amount }}
                        </td>
                        <td>
                          {{ this.$helperService.getFormatedDate(data?.created_at) }}
                        </td>
                        <td>
                          <button
                            class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                            v-if="data.status != 'REJECTED' && access.can_read"
                            v-on:click="
                              $router.push('/view-transaction-details/' + data.id)
                            "
                          >
                            <i class="bx bx-show"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ErrorComponent @retry="getTransactionList(1)" ref="errorComponent" />
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-lg-12 mt-3">
                  <div class="page-bottom-pagination text-right">
                    <Pagination
                      @page-change="pageChange"
                      @items-per-page-change="itemsPerPageChange"
                      ref="listPagination"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
</template>

<script>
import ErrorComponent from "@/components/ErrorComponent";
import helperService from "../../services/HelperService";
// import { Form, Field, ErrorMessage } from "vee-validate";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "MyProducts",
  components: {
    DeleteModal,
    ErrorComponent,
    Pagination,
    //  Form,
    //  Field,
    // ErrorMessage
  },
  data() {
    return {
      currentTab: "PRODUCT",
      TransactionList: [],
      serviceList: [],
      categoryList: [],
      subCategoryList: [],
      searchObj: {
        keyword: "",
      },

      currentPage: 1,
      loader: true,
      itemsPerPage: 10,
      access: { can_create: 1, can_update: 1, can_read: 1, can_delete: 1 },
    };
  },
  mounted() {
    // this.changeTab("PRODUCT");
    // this.getServiceList(0);
    this.getTransactionList(1);
    this.getCategory("product");
    // this.getSubCategory();
  },
  methods: {
    pageChange(page) {
      this.getTransactionList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getTransactionList(1);
    },

    getTransactionList(page, sortBy, orderBy) {
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.searchObj.sort_order = orderBy;
      }
      if (!page) {
        page = 1;
      }
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      // this.searchObj.row_per_page = this.itemsPerPage;

      this.TransactionList = [];
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: "transactions",
          _body: this.searchObj,
        })
        .then((res) => {
          this.TransactionList = res.list;
          this.access = res.manage_transactions;
          this.searchObj.offset = res.next_offset;

          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
            if (res.count != null && res.count > 0 && this.access.can_read) {
              this.$refs.listPagination.setTotalCount(res.count);
            } else {
              this.$refs.listPagination.setTotalCount(0);
            }
          }

          if (this.$refs.errorComponent) {
            if (this.access.can_read == 0) {
              this.$refs.errorComponent.setData(
                res,
                this.$t("label.Sorry, you don't have permission")
              );
              this.$refs.errorComponent.updateLoader(false);
              return;
            }
            this.$refs.errorComponent.setData(
              res,
              this.$t("label.Transaction Not Available")
            );
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },

    router() {
      if (this.currentTab == "PRODUCT") {
        this.$router.push("/add-product");
      } else {
        this.$router.push("/add-service");
      }
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        this.$t("label.Delete"),
        this.$t("label.Are you sure you want to delete"),
        id
      );
    },
    deleteRecord(id) {
      var url = "";
      if (this.currentTab == "PRODUCT") {
        url = "product";
      } else {
        url = "service";
      }
      this.$api
        .deleteAPI({
          _action: url + "/" + id,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.deleteModal.closeModal();
          this.getTransactionList(0);
          this.getServiceList(0);
        })
        .catch(() => {});
    },
    getCategory(data) {
      this.$api
        .getAPI({
          _action: data + "/categories",
        })
        .then((res) => {
          this.categoryList = res.list;
        })
        .catch(() => {});
    },

    getSubCategory() {
      var id = this.searchObj.category_id;
      console.log("object");
      var obj = {};
      obj.parent_category_id = id;
      this.$api
        .getAPI({
          _action: "product/categories",
          _body: obj,
        })
        .then((res) => {
          this.subCategoryList = res.list;
        })
        .catch(() => {});
    },
    sorting(sortBy, id) {
      var obj = helperService.sorting(sortBy, id);
      this.getTransactionList(0, obj.order_by, obj.sort_by);
    },
    UpdateStatus(status, id) {
      var obj = {};
      obj.status = status;
      {
        this.$api
          .putAPI({
            _action: "change/status/" + id,
            _body: obj,
            _buttonId: "update-status",
          })
          .then((res) => {
            this.getTransactionList(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {});
      }
    },
  },
};
</script>

