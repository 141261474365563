<template>
  <div class="d-lg-flex no-right-sidebar" id="main-wraper">
    <div class="page-wrapper no-right-sidebar">
      <div class="page-content">
        <div class="row mb-4 justify-content-center">
          <div class="col-lg-12">
            <div class="row p-3 align-items-center text-dark">
              <div class="col-md-6 col-5">
                <h2 class="page-title mb-md-0">
                  <a
                    href="javascript:void(0)"
                    v-on:click="$router.go(-1)"
                    class="back-arrow"
                  >
                    <i class="bx bx-arrow-back"></i
                  ></a>
                  {{ $t("label.Upload Banner") }}
                </h2>
              </div>
            </div>
            <div
              class="card card-body content-card mb-4 rounded-l border-0 shadow-sm"
              style="width: 140%"
            >
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <form class="form-style">
                    <div class="row justify-content-center">
                      <div class="col-12 mb-4 text-center">
                        <div class="mb-3">
                          <img
                            :src="
                              detail.banner && detail.banner != null
                                ? detail.banner
                                : '/images/placeholder.png'
                            "
                            class="border border-light"
                            style="border-width: 3px !important"
                            alt="image thumb"
                            id="preview"
                            width="500"
                            height="350"
                          />
                        </div>
                        <button
                          type="button"
                          class="btn px-3 upload-img-merge-btn btn-secondary w-75 text-capitalize"
                          for="pickFile"
                        >
                          {{ $t("label.Add Photo") }}
                          <input
                            type="file"
                            class="btn upload-img"
                            id="pickFile"
                            @change="selectedFile($event)"
                          />
                        </button>
                      </div>
                      <div class="col-lg-12 col-md-12">
                        <label for="url">{{ $t("label.Url") }}</label>
                        <span class="text-danger"></span>
                        <div class="form-group with-icon">
                          <Field
                            type="text"
                            id="url"
                            name="url"
                            v-model="detail.url"
                            class="form-control"
                            rules="required:url"
                            :validateOnInput="true"
                          />
                        </div>
                        <ErrorMessage name="url" class="validation-msg" />
                      </div>
                    </div>
                    <br />
                    <br />
                    <div class="text-center mt-3">
                      <button
                        type="button"
                        class="btn btn-brand-1 mb-3 mr-2 px-5"
                        v-on:click="$router.go(-1)"
                      >
                        {{ $t("label.Cancel") }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-brand-1 mb-3 px-5"
                        id="save-button"
                        v-on:click="save()"
                      >
                        {{ $t("label.Save") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field } from "vee-validate";
export default {
  name: "AddBanner",
  components: {
    Field,
  },
  data() {
    return {
      detail: {
        banner: undefined,
        url: "",
      },
      file: undefined,
    };
  },
  mounted() {
    // alert("hello");
  },
  methods: {
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert(this.$t("label.Invalid file formate, please use jpeg or png file."));
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert(this.$t("label.File must be less then 10 MB"));
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    // addUrl() {
    //   this.$api
    //     .postApt({
    //       _action: "banner",
    //       _body: this.detail,
    //     })
    //     .then((res) => {
    //       this.detail.banner = res.info;
    //       this.save();
    //     });
    // },
    save() {
      if (!this.file) {
        this.$notify({
          type: "error",
          text: "Please select image once",
        });
      }
      this.$api
        .uploadImageAPI({
          _action: "banner",
          _key: "banner",
          _file: this.file,
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          this.detail.banner = res.path;

          this.$router.push("/banners");
        });
    },
  },
};
</script>

