<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 95%; margin-left: 2%">
        <div class="page-content" style="background-color: white">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row p-3 align-items-center">
                <div class="col-md-6 col-5">
                  <h2 class="page-title mb-md-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ this.typeOfReport }}

                    {{ $t("label.Reports") }}
                  </h2>
                </div>
              </div>
              <Form class="form-style floating-form" @submit="generate()">
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-6 mr-1">
                      <div class="form-group">
                        <label for="role">{{ $t("label.Parameter") }}</label>
                        <span class="text-danger">*</span>
                        <Field
                          as="select"
                          class="custom-select"
                          style="height: 50px"
                          v-model="detail.type"
                          name="type"
                          rules="required:type"
                        >
                          <option value="">{{ $t("label.Select") }}</option>
                          <option value="DAILY">{{ $t("label.Daily") }}</option>
                          <option value="MONTHLY">{{ $t("label.Monthly") }}</option>
                          <option value="YEARLY">{{ $t("label.Yearly") }}</option>
                        </Field>
                      </div>
                      <ErrorMessage name="type" class="validation-msg mt-3" />
                    </div>
                    <div class="col-lg-6" v-if="detail.type == 'MONTHLY'">
                      <div class="form-group">
                        <label for="role">{{ $t("label.Month") }}</label>
                        <span class="text-danger">*</span>
                        <Field
                          as="select"
                          class="custom-select"
                          style="height: 50px"
                          v-model="detail.month"
                          name="month"
                          rules="required:month"
                        >
                          <option value="">{{ $t("label.Select") }}</option>
                          <option value="jan">{{ $t("label.January") }}</option>
                          <option value="fab">{{ $t("label.February") }}</option>
                          <option value="mar">{{ $t("label.March") }}</option>
                          <option value="apr">{{ $t("label.April") }}</option>
                          <option value="may">{{ $t("label.May") }}</option>
                          <option value="jun">{{ $t("label.June") }}</option>
                          <option value="jul">{{ $t("label.July") }}</option>
                          <option value="aug">{{ $t("label.August") }}</option>
                          <option value="sep">{{ $t("label.September") }}</option>
                          <option value="oct">{{ $t("label.October") }}</option>
                          <option value="nov">{{ $t("label.November") }}</option>
                          <option value="dec">{{ $t("label.December") }}</option>
                        </Field>
                      </div>
                      <ErrorMessage name="month" class="validation-msg mt-3" />
                    </div>
                    <div
                      class="col-md-6"
                      v-if="detail.type == 'MONTHLY' || detail.type == 'YEARLY'"
                    >
                      <div class="form-group">
                        <label for="role">{{ $t("label.Year") }}</label>
                        <span class="text-danger">*</span>
                        <Field
                          as="select"
                          class="custom-select"
                          style="height: 50px"
                          v-model="detail.year"
                          name="year"
                          rules="required:year"
                        >
                          <option value="">{{ $t("label.Select") }}</option>
                          <option
                            v-for="(data, index) in years"
                            :key="index"
                            :value="data"
                          >
                            {{ data }}
                          </option>
                        </Field>
                      </div>
                      <ErrorMessage name="year" class="validation-msg" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6" v-if="detail.type == 'DAILY'">
                      <div class="floating-form-group with-icon">
                        <Datepicker
                          v-model="detail.from_date"
                          hideInputIcon
                          :clearable="false"
                          :enableTimePicker="false"
                          format="yyyy-MM-dd"
                          :placeholder="$t('label.From Date')"
                          :locale="lang"
                          :cancelText="$t('label.Cancel')"
                          :selectText="$t('label.Select')"
                        ></Datepicker>
                        <img src="/images/calendar.svg" width="20" class="icon" />
                      </div>
                      <ErrorMessage name="fromDate" class="validation-msg" />
                    </div>

                    <div class="col-md-6" v-if="detail.type == 'DAILY'">
                      <div class="floating-form-group with-icon">
                        <Datepicker
                          v-model="detail.to_date"
                          hideInputIcon
                          :clearable="false"
                          :enableTimePicker="false"
                          format="yyyy-MM-dd"
                          :placeholder="$t('label.To Date')"
                          :locale="lang"
                          :cancelText="$t('label.Cancel')"
                          :selectText="$t('label.Select')"
                        ></Datepicker>

                        <img src="/images/calendar.svg" width="20" class="icon" />
                      </div>
                      <ErrorMessage name="toDate" class="validation-msg" />
                    </div>
                  </div>
                </div>
                <div class="modal-footer pb-0">
                  <button
                    class="fill-btn btn-success"
                    id="save-button"
                    style="width: 18%; border-radius: 5px"
                  >
                    {{ $t("label.Generate") }}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import Datepicker from "@vuepic/vue-datepicker";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "ReportView",
  components: {
    Form,
    Field,
    ErrorMessage,
    Datepicker,
  },
  data() {
    return {
      year: "",
      typeOfReport: "",
      report: this.$route.params.type,
      detail: {
        type: "",
      },
      years: [],
      lang:"en"
    };
  },
  mounted() {    this.lang = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "en";
    this.year = new Date().getFullYear();
    this.getYear();
    this.convertNormalText();
  },
  methods: {
    getYear() {
      for (var i = 2015; i <= this.year; i++) {
        this.years.push(i);
      }
    },
    convertNormalText() {
      var oldString = this.report;
      var newString = oldString.replace("_", " ");
      console.log(
        "replace String" + newString.charAt(0).toUpperCase() + newString.slice(1)
      );
      this.typeOfReport = newString.charAt(0).toUpperCase() + newString.slice(1);
    },
    generate() {
      (this.detail.from_date = moment(this.detail.from_date).format("YYYY-MM-DD")),
        (this.detail.to_date = moment(this.detail.to_date).format("YYYY-MM-DD")),
        this.$api
          .postAPI({
            _action: "report/" + this.report,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (res) {
              // var url = res.file_name;

              window.open(res.file_name);
            }
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {});
    },
  },
};
</script>

