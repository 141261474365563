<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row p-3 align-items-center">
                <div class="col-md-6 col-5">
                  <h2 class="page-title mb-md-0">
                    <a v-on:click="$router.go(-1)" class="back-arrow">
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ id ? $t("label.Edit") : $t("label.Add") }} {{ $t("label.Roles") }}
                  </h2>
                </div>
              </div>
            </div>
            <div class="card-body py-0 px-0">
              <Form @submit="addRole()">
                <div class="row">
                  <div class="col-lg-4 col-sm-4 form-group" style="margin-left: 3px">
                    <Field
                      type="text"
                      name="role_name"
                      v-model="detail.role_name"
                      class="form-control filled"
                      :placeholder="$t('label.Role') + ' ' + $t('label.Name')"
                      rules="required:name"
                      :validateOnInput="true"
                    />
                  </div>
                  <ErrorMessage name="role_name" class="validation-msg" />
                  <!-- <div
                    class="col-lg-4 col-sm-4 form-group"
                    style="margin-left: 3px"
                  >
                    <Flield
                      type="text"
                      name="description"
                      v-model="detail.description"
                      class="form-control filled"
                      placeholder="Description"
                      rules="required:description"
                      :validateOnInput="true"
                    />
                  </div>
                  <ErrorMessage name="description" class="validation-msg" /> -->
                </div>
                <div class="card border mb-0 radius-15">
                  <div class="card-body p-0 pb-4">
                    <div class="table-responsive">
                      <div class="dataTables_wrapper dt-bootstrap4">
                        <table
                          class="table table-striped table-bordered dataTable"
                          style="width: 100%"
                          role="grid"
                          aria-describedby="example_info"
                        >
                          <thead>
                            <tr>
                              <th>{{ $t("label.Access to") }}</th>
                              <th>{{ $t("label.View") }}</th>
                              <th>{{ $t("label.Add") }}</th>
                              <th>{{ $t("label.Edit") }}</th>
                              <th>{{ $t("label.Delete") }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(data, index) in detail.role_access" :key="index">
                              <td>{{ data.menu }}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="data.can_read"
                                  v-if="
                                    (data.can_create ||
                                      data.can_update ||
                                      data.can_delete) == 1
                                      ? true
                                      : false
                                  "
                                  true-value="1"
                                  checked
                                  false-value="0"
                                  v-on:click="checkClick(data)"
                                  :id="'id_' + index"
                                  :name="'name_' + index"
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="data.can_create"
                                  true-value="1"
                                  false-value="0"
                                  :id="'id_' + index"
                                  v-on:click="checkClick(data)"
                                  :name="'name_' + index"
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="data.can_update"
                                  true-value="1"
                                  false-value="0"
                                  v-on:click="checkClick(data)"
                                  :id="'id_' + index"
                                  :name="'name_' + index"
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="data.can_delete"
                                  true-value="1"
                                  v-on:click="checkClick(data)"
                                  false-value="0"
                                  :id="'id_' + index"
                                  :name="'name_' + index"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-lg-12 col-sm-12 text-center mt-2">
                        <button id="save-button" class="fill-btn btn-success px-4 ml-3">
                          {{ $t("label.Save") }}</button
                        ><button
                          type="button"
                          class="fil-btn btn-danger px-4 ml-3"
                          id="cancel-button"
                          v-on:click="this.$router.go(-1)"
                        >
                          {{ $t("label.Cancel") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "AddRole",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      detail: {
        status: "ACTIVE",
        role_access: [],
      },
      id: this.$route.params.id,
      // menus:[]
    };
  },
  mounted() {
    this.getMenus();
  },
  methods: {
    checkClick(data) {
      if ((data.can_create || data.can_update || data.can_delete) == 1) {
        data.can_read == 1;
      }
    },
    getMenus() {
      if (this.id) {
        var obj = {};
        obj.id = this.id;
      }
      this.$api
        .getAPI({
          _action: "menues",
          _body: obj,
        })
        .then((res) => {
          this.detail.role_access = res.list;
          this.detail.role_name = res.name;
        });
    },
    addRole() {
      // console.log(this.detail.role_access);
      // this.detail.role_access.forEach((obj) => {
      //   if (obj.can_create == 1 || obj.can_update == 1 || obj.can_delete == 1) {
      //     obj.can_read == 1;
      //   }
      //   // this.$set.obj.can_read
      // });
      if (this.id) {
        this.$api
          .putAPI({
            _action: "role/" + this.id,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$router.go(-1);
            this.$notify({
              type: "success",
              text: res.message,
            });
          });
      } else {
        this.$api
          .postAPI({
            _action: "role",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$router.go(-1);
            this.$notify({
              type: "success",
              text: res.message,
            });
          });
      }
    },
  },
};
</script>

